import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AdminPanelHeader } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { profileImage, isImage, dateFormat, titlizeString } from '../Helper';
import { Loader } from 'whealth-core-web/components';
import CoreInputBox from '../CoreInputBox';
import { location, blueEdit } from 'res/images';
import '../Apollo/RenderApolloTab.css';
import CoreButton from '../CoreButton';
import EditInvoiceModal from '../InvoiceModal/EditInvoiceModal';
import PayoutModal from '../PayoutModal';
import { ToastContainer } from 'react-toastify';
import CommonToolTip from '../CommonToolTip';
import { Button, Modal } from 'rsuite';

function ViewInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const { orderId, vendorId, patientId } = useParams();
  const [orderSummary, setOrderSummary] = useState({});
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [payoutData, setPayoutData] = useState(null);
  const [modalOpen, setModalOpen] = useState({
    visible: false,
    fileUrl: '',
  });

  useEffect(() => {
    if (orderId) {
      getInvoice();
    }
  }, [orderId, refreshData]);

  const getInvoice = () => {
    setInvoiceLoading(true);
    ApiClient.viewInvoice({ order_id: orderId, vendor_id: vendorId })
      .then((res) => {
        let discountedTotal = 0;
        res.data.items.map((item) => {
          discountedTotal =
            discountedTotal +
            parseFloat(item?.order_item_details?.item_amount) * item?.order_item_details?.ordered_quantity;
        });
        const discount = parseFloat(res?.data?.order_details?.discounted_amount);
        const disc_per = ((discount / parseFloat(res?.data?.order_details?.before_discount_amount)) * 100).toFixed(2);

        setOrderSummary({
          order_total: res?.data?.vendor_amount,
          shipping_amount: res?.data?.shipping_cost,
          platform_fee: res?.data?.platform_fee || '0',
          other_fee: res?.data?.other_fee || '0',
          discount: res?.data?.discount,
          order_paid_amount: (
            parseFloat(res?.data?.vendor_amount) +
            parseFloat(res?.data?.shipping_cost) +
            parseFloat(res?.data?.platform_fee || '0') +
            parseFloat(res?.data?.other_fee || '0')
          ).toFixed(2),
          discountedTotal: discountedTotal.toFixed(2),

          customer_order_total: parseFloat(res?.data?.order_details?.before_discount_amount),
          customer_shipping_amount: parseFloat(res?.data?.order_details?.shipping_amount),
          customer_discount: discount,
          customer_discount_percentage: disc_per,
          customer_order_paid_amount: res?.data?.order_details?.total_amount,
        });
        setInvoiceDetails(res?.data);
        setInvoiceLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showTree1 = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      title: invoiceDetails?.user_details?.full_name,
      icon: profileImage(invoiceDetails?.user_details?.gender, invoiceDetails?.user_details?.profile_url),
      link: `/patient/${patientId}/dashboard`,
    },
    {
      title: 'View Order',
      icon: '',
      link: '',
    },
    {
      title: 'View Invoice',
      icon: '',
      link: '',
    },
  ];

  const renderSearchBox = () => {
    return (
      <div className="w-100">
        <CoreInputBox placeholder="Search a Order by Patient name or Order ID" setValue={(val) => {}} value={''} />
      </div>
    );
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: 'Partially Ordered',
      id: 'ordered_partially',
    },
    {
      title: 'Unfulfilled',
      id: 'in_progress',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
  ];

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        const type = blob.type.split('/');
        const ext = type[type.length - 1];
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileName}.${ext}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
    // Open image in a new tab
    const newTab = window.open(fileUrl, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open image in a new tab.');
    }
  };

  const orderStatusWithColor = (ehr_order_status, isHeading) => {
    return (
      <div className={`d-flex align-items-center ${isHeading && `justify-content-end`}`}>
        <span
          className={`status-text align-items-center badge capitalize active align-items-center d-flex ${ehr_order_status}`}
        >
          {titlizeString(orderStatus.find((item3) => item3.id == ehr_order_status)?.title) || ehr_order_status}
        </span>
      </div>
    );
  };

  const renderProfitLoss = () => {
    const val = (parseFloat(orderSummary?.order_paid_amount) - parseFloat(orderSummary?.discountedTotal)).toFixed(2);
    if (parseFloat(val) < 0)
      return <p style={{ color: 'green', alignSelf: 'flex-end', margin: 5 }}>Profit of {val} made on this order</p>;
    else if (parseFloat(val) > 0)
      return <p style={{ color: 'red', alignSelf: 'flex-end', margin: 5 }}>Loss of {val} made on this order</p>;
    else return <></>;
  };

  const renderOrders = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '80vh' }}>
        <div style={{ marginTop: 5, marginBottom: 15 }}>
          <span
            style={{
              fontSize: '14px',
              color: 'black',
              fontWeight: '500',
              textDecorationColor: '#757575',
            }}
          >
            Invoice Date:{'  '}
            <span
              style={{
                fontSize: '13px',
                color: '#848484',
                fontWeight: '500',
                textDecorationColor: '#757575',
              }}
            >
              {dateFormat(invoiceDetails?.invoice_date)}
            </span>
          </span>
        </div>

        <div className="d-flex flex-row justify-content-between " style={{ width: '95%' }}>
          <div className=" d-flex justify-content-between" style={{ width: '100%' }}>
            <p style={{ position: 'absolute' }}>
              {orderStatusWithColor(invoiceDetails?.order_details?.ehr_order_status, true)}
            </p>
            <div className="d-flex flex-col justify-content-between items-center space-x-4 order-modal">
              <div
                className="d-flex align-items-center"
                style={{ background: '#F9FAFE', padding: '10px', borderRadius: '8px', width: '50%' }}
              >
                <div className="mr-2 mt-4 text-capitalize" style={{ fontWeight: '500' }}>
                  <p>Source: {invoiceDetails?.order_details?.source}</p>
                  <p>Order ID : {invoiceDetails?.order_details?.order_id}</p>
                </div>
                <div className="vl">.</div>
                <div className="mx-2 patient-name" style={{ fontWeight: 'bold', color: '#243b86' }}>
                  <img
                    className="listUserImage rounded-circle"
                    style={{ width: '20px', height: '20px', marginRight: '5px' }}
                    src={profileImage(invoiceDetails?.user_details?.gender, invoiceDetails?.user_details?.profile_url)}
                  />
                  {invoiceDetails?.user_details?.full_name}
                </div>
                <div className="mx-2 patient-address d-flex">
                  <div>
                    <img src={location} alt="location" />
                  </div>

                  <div className="complete-address" style={{ fontWeight: '500' }}>
                    {invoiceDetails?.order_details?.shipping_address}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-bs-toggle={'offcanvas'} data-bs-target={'#invoiceModal'} onClick={() => {}}>
            <img src={blueEdit} className="editModal" />
          </div>
        </div>

        <div className=" diet-regime cardDataBorder w-100 patientordertabtable fulfilled-suborder">
          <div
            className="table-suborder"
            //style={item2?.vendor?.vendor_name === 'WareIQ' ? { overflowX: 'scroll' } : {}}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div className="table-heading d-flex justify-content-between  align-items-center mb-3">
                <div class="d-flex">
                  {invoiceDetails?.items && (
                    <div>
                      <b>
                        Sub-Order 1:{' '}
                        {invoiceDetails?.items[0]?.vendor?.vendor_logo && (
                          <img
                            src={invoiceDetails?.items[0]?.vendor?.vendor_logo}
                            height="15px"
                            width="15px"
                            style={{ margin: '-2px 4px 0 3px', objectFit: 'contain' }}
                          />
                        )}
                        {invoiceDetails?.items[0]?.vendor?.vendor_name}
                      </b>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Medicine</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Vendor Amount</th>
                  <th>Disc. Price</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoiceDetails?.items &&
                  invoiceDetails?.items?.map((medicineItem, medicineIndex) => {
                    return (
                      <tr>
                        <td>
                          <b>{medicineIndex + 1}</b>
                        </td>
                        <td className="capitalize">
                          <div className="d-flex flex-column text-center">
                            <b>{medicineItem?.order_item_details?.product_item?.product?.product_name}&nbsp;</b>
                            {medicineItem?.order_item_details?.product_item?.variation?.name} of{' '}
                            {medicineItem?.order_item_details?.product_item?.variation?.variation}
                          </div>
                        </td>
                        <td>
                          <div className="w-60" style={{ width: '60px' }}>
                            {medicineItem?.quantity}
                          </div>
                        </td>
                        <td>Rs.{parseFloat(medicineItem?.order_item_details?.item_mrp).toFixed(2)}</td>
                        <td>
                          Rs.
                          {parseFloat(medicineItem?.vendor_amount).toFixed(2)}
                        </td>
                        <td>Rs.{parseFloat(medicineItem?.order_item_details?.item_amount).toFixed(2)} </td>
                        <td>
                          Rs.
                          {(
                            parseFloat(medicineItem?.order_item_details?.item_amount).toFixed(2) *
                            medicineItem?.quantity
                          ).toFixed(2)}{' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between mt-4" style={{ width: '95%' }}>
          <div style={{ width: '100%' }}>
            <div style={{ marginBottom: 10 }}>Invoice Preview</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
              {invoiceDetails?.invoice_files ? (
                invoiceDetails?.invoice_files?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      width: '40%',
                      height: '312px',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    {/* Download Text */}
                    <div
                      className="mb-1  pointer"
                      onClick={() => {
                        downloadFile(item.file, item.filename);
                      }}
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: '10px',
                        right: '20px',
                        backgroundColor: '#F8F9FA',
                        borderRadius: '4px',
                        height: 28,
                        width: 28,
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '3px',
                      }}
                    >
                      <CommonToolTip
                        placement={'top'}
                        item={<span class="material-icons-outlined">download</span>}
                        showValue={'Download'}
                      />
                    </div>

                    <div
                      className="mb-1  pointer"
                      onClick={() => {
                        setModalOpen({
                          visible: true,
                          fileUrl: item?.file,
                        });
                      }}
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: '10px',
                        right: '60px',
                        backgroundColor: '#F8F9FA',
                        borderRadius: '4px',
                        height: 27,
                        width: 27,
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '3px',
                      }}
                    >
                      <CommonToolTip
                        placement={'top'}
                        item={<span class="material-icons-outlined">visibility</span>}
                        showValue={'View'}
                      />
                    </div>

                    {isImage(item?.file) ? (
                      <img
                        src={item?.file}
                        alt={`Preview ${index}`}
                        className="img-fluid rounded"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <iframe
                        src={`${item.file}#toolbar=0`}
                        title="PDF Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          border: 'none',
                        }}
                      ></iframe>
                    )}
                  </div>
                ))
              ) : (
                <p>No invoice file available</p>
              )}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 10, width: '80%' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '50%', flexShrink: 0, alignSelf: 'flex-start' }}
            >
              <div className="p-3 bg-light rounded">
                <h6 className="fw-bold mb-3">Customer Payment Summary</h6>
                <div className="d-flex justify-content-between mb-2">
                  <div>Order Total</div>
                  <div>Rs. {orderSummary?.customer_order_total?.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Discount</div>
                  <div>
                    Rs. {orderSummary?.customer_discount} {`(${orderSummary?.customer_discount_percentage}%)`}
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Shipping</div>
                  <div>
                    {orderSummary.customer_shipping_amount == '0'
                      ? 'Free'
                      : `Rs. ${orderSummary.customer_shipping_amount}`}
                  </div>
                </div>
                <div className="hrLine mb-3" />
                <div className="d-flex justify-content-between fw-bold">
                  <div>Total</div>
                  <div>Rs. {orderSummary?.customer_order_paid_amount}</div>
                </div>
              </div>
            </div>

            <div
              style={{ display: 'flex', flexDirection: 'column', width: '50%', flexShrink: 0, alignSelf: 'flex-start' }}
            >
              <div className="p-3 bg-light rounded">
                <h6 className="fw-bold mb-3">Vendor Summary</h6>
                <div className="d-flex justify-content-between mb-2">
                  <div>Order Total</div>
                  <div>Rs. {orderSummary?.order_total}</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Shipping</div>
                  <div>Rs. {orderSummary?.shipping_amount == '0' ? 'Free' : orderSummary?.shipping_amount}</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Platform Fee</div>
                  <div>Rs. {orderSummary?.platform_fee}</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Other Fee</div>
                  <div>Rs. {orderSummary?.other_fee}</div>
                </div>
                <div className="hrLine mb-3" />
                <div className="d-flex justify-content-between fw-bold">
                  <div>Total</div>
                  <div>Rs. {orderSummary?.order_paid_amount}</div>
                </div>
              </div>
              {renderProfitLoss()}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '20px', marginBottom: '0px', width: '95%' }}>
          <div className="hrLine " style={{ background: '#D1DCFF' }} />

          <div className="d-flex flex-row" style={{ gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="reusableBtnActive  reusableBtn"
              style={{
                background: '#fff',
                border: '1px solid #133696',
                color: '#133696',
                padding: '14px',
                fontWeight: '800',
                fontSize: '15px',
                margin: '15px 0px',
              }}
            >
              Go Back
            </div>
            {invoiceDetails?.items &&
              invoiceDetails?.items.length > 0 &&
              invoiceDetails?.items[0]?.order_item_details?.payout_id === null && (
                <div data-bs-toggle={'offcanvas'} data-bs-target={'#payoutModal'}>
                  <CoreButton
                    onClick={() => {
                      setPayoutData({
                        invoiceId: invoiceDetails?.id,
                        vendorName: invoiceDetails?.items[0]?.vendor?.vendor_name,
                      });
                    }}
                    customClass="btn btn-secondary btn-lg"
                    title="Generate Payout"
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader
          searchBox={renderSearchBox}
          iconTitle="person_outline"
          showTree={showTree1}
          title="Patient"
          placeholder="Search Patients"
        />
      </div>
    );
  };

  return (
    <>
      <div className="articleHeader stickyHeader">
        {renderHeader1()}
        <div className="hrLine mb-3" />
      </div>

      <div>
        <div style={{ marginLeft: '230px' }} className="wrapperContent">
          {renderOrders()}
        </div>

        <Modal
          backdrop="static"
          size="lg"
          open={modalOpen.visible}
          onClose={() => {
            setModalOpen({
              visible: false,
              fileUrl: '',
            });
          }}
        >
          <Modal.Header>
            <Modal.Title>Invoice View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isImage(modalOpen.fileUrl) ? (
              <img
                src={modalOpen.fileUrl}
                className="img-fluid rounded"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <iframe
                src={`${modalOpen.fileUrl}#toolbar=0&zoom=75`}
                onContextMenu="return false;"
                width="100%"
                height={window.innerHeight}
              />
            )}
          </Modal.Body>
          <Modal.Footer className="mt-4">
            {/* <Button onClick={handlePublishAndPrint} appearance='primary'>
             Publish
           </Button> */}
            <Button
              onClick={() => {
                setModalOpen({
                  visible: false,
                  fileUrl: '',
                });
              }}
              appearance="defualt"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <EditInvoiceModal vendorOrder={invoiceDetails} setRefreshData={setRefreshData} />
        <PayoutModal payoutData={payoutData} setRefreshData={setRefreshData} />

        <Loader show={invoiceLoading} />
        <ToastContainer />
      </div>
    </>
  );
}

export default ViewInvoice;
