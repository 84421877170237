import { AdminPanelHeader, DashBoardHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setAlerts, setLocations, setPatientDetails } from 'redux/Slices';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import {
  CommonAlert,
  CommonBorderBox,
  Loader,
  PostHeader,
  QuestionsTab,
  AppCaptureTab,
} from 'whealth-core-web/components';
import { dateFormatStringSingleDate, profileImage } from 'whealth-core-web/components/Helper';
import CategoryCard from './CategoryCard';
import { getOverallPercentage } from 'whealth-core-web/components/Helper';
import { whatsapp } from 'res/images';

function Baselining() {
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  const patientDetails = useSelector((state) => state.sliceReducer.patientDetails);
  const dispatch = useDispatch();
  let userLocalData = localStorage.getItem('userDetails');
  userLocalData = JSON.parse(userLocalData);

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [errors, serErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const callLog = location?.state?.key;
  const [status, setStatus] = useState({
    link: !callLog || location?.state?.key === 'appointment' ? 'patient_profile' : 'profile_questions',
  });

  const [isNivanCare, setIsNivanCare] = useState(false);

  useEffect(() => {
    setUserDetails({ ...patientDetails, full_name: location?.state?.name });
  }, [location?.state?.name]);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (id) {
      if (Object.keys(patientDetails).length > 0) {
        setUserDetails(patientDetails);
      } else {
        getPatientData();
      }
    } else {
      setUserDetails({});
    }
  }, [id, patientDetails]);

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientProfile(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        newuserData?.locations?.map((item) => tempLocations.push(item.id));
        let reformattedData = { ...newuserData, locations: tempLocations };
        reformattedData.care_manager_id = reformattedData?.care_manager?.id;
        reformattedData.care_plan_id = reformattedData?.care_plan?.id;
        setUserDetails(reformattedData);
        dispatch(setPatientDetails(reformattedData));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getAppCaptureScore = () => {
    let totalQuestions = 0;
    let nonNullAnswers = 0;

    categories.forEach((category) => {
      category.questions.forEach((question) => {
        totalQuestions++;
        if (question.latest_answer !== null) {
          nonNullAnswers++;
        }
      });
    });

    return totalQuestions > 0 ? Math.round((nonNullAnswers / totalQuestions) * 100) : 0;
  };

  const getHealthCoachCaptureScore = () => {
    if (!userDetails.profile_info || !Array.isArray(userDetails.profile_info)) {
      return 0;
    }

    const nonNullCount = userDetails?.profile_info.reduce((count, info) => count + (info.answer !== null ? 1 : 0), 0);
    const totalCount = userDetails?.profile_info.length;
    return Math.round((nonNullCount / totalCount) * 100);
  };

  useEffect(() => {
    setCategories(patientDetails?.base_lining || []);
  }, [patientDetails]);

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const showTree = id
    ? [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: userLocalData?.name,
          link: `/patient/${id}/dashboard`,
          icon: profileImage(userLocalData?.gender, userLocalData?.image),
        },
        {
          title: 'Baselining',
        },
      ]
    : [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: 'Create Patient',
        },
      ];

  // const leftSideData = [
  //   { title: !id ? "Create Patient" : "Update patient", style: { color: "var(--themeDarkColor)" } },
  // ];

  const renderAdminPanelHeader = useMemo(() => {
    return <AdminPanelHeader showTree={showTree} iconTitle="person_outline" title="Patient" />;
  }, []);

  // const renderDashBoardHeader = useMemo(() => {
  //   return <DashBoardHeader leftData={leftSideData} />;
  // }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderPageHeader = useMemo(() => {
    const postLinks = [
      {
        title: !id ? 'Create Patient' : `App-Captured (${getAppCaptureScore()}%)`,
        link: 'patient_profile',
      },
    ];

    if (id) {
      postLinks.push({
        title: `Health Coach (${getHealthCoachCaptureScore()}%)`,
        link: 'profile_questions',
      });
    }
    return (
      <div>
        <div style={{ fontWeight: '600', marginBottom: '20px', fontSize: '16px' }}>
          Baselining
          <span style={{ color: 'gold', fontWeight: '600' }}>{` ( ${getOverallPercentage(
            categories,
            userDetails.profile_info
          )}% Complete)`}</span>
        </div>
        <PostHeader
          callLog={callLog}
          postLinks={postLinks}
          onClickHandle={(data) => {
            setStatus(data);
          }}
        />
        {status.link === 'patient_profile' && (
          <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            {categories.map((category, index) => (
              <CategoryCard
                key={index}
                name={category.display_title}
                value={category.score}
                progress={category.completion}
                onPress={() => {
                  setSelectedIndex(index);
                }}
                isSelected={index === selectedIndex}
              />
            ))}
          </div>
        )}
        <div className="hrLine mt-4" />
      </div>
    );
  }, [categories, selectedIndex, status]);

  const renderCreatePost = () => {
    return (
      <div className="px-4 wrapperContent">
        <CommonBorderBox className="d-flex justify-content-between teamBorderBox">
          <div className="w-100">
            <AppCaptureTab data={userDetails} selectedIndex={selectedIndex} />
          </div>
        </CommonBorderBox>
      </div>
    );
  };

  const renderQuestionsTab = () => {
    return (
      <>
        <div className="px-4 wrapperContent d-flex">
          <div className="teamBorderBox mb-4">
            <div className="fw-bold fs-6">Enrolment Summary</div>
            <div> {userDetails?.ee_call_summary}</div>
            <div className="fs-6 mt-2"> Special notes - {userDetails?.special_notes}</div>
          </div>
          <div className="w-100 teamBorderBox">
            <QuestionsTab data={userDetails} />
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    const renderDataObj = {
      patient_profile: renderCreatePost,
      profile_questions: renderQuestionsTab,
    };

    return (
      <>
        <div className="headerFiles articleHeader stickyHeader">
          {renderAdminPanelHeader}
          {showAlert && (
            <div className="mt-2">
              <CommonAlert isShowAlert={showAlert} alertType={alertType} setIsShowAlert={setShowAlert} />
            </div>
          )}
          {renderPageHeader}
        </div>
        {renderDataObj[status.link]()}
      </>
    );
  };

  return (
    <div>
      <div>
        {/* {sideBar} */}
        {renderBody()}
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default Baselining;
