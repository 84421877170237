import { useState,useEffect } from 'react';

export const useInvoiceForm = (vendorOrder) => {
  
  const [formData, setFormData] = useState({
    invoiceFiles: [],
    invoiceDate: '',
    shippingCost: '0',
    platformFee: '0',
    otherFee: '0',
    suborderDetails: [
      {
        medicineId: '',
        medicineName: '',
        medicineVariation: '',
        quantity: '',
        unitCost: '',
      },
    ],
  });

  const [formErrors, setFormErrors] = useState({
    invoiceFiles: [],
    invoiceDate: '',
    shippingCost: '',
    platformFee: '',
    otherFee: '',
    suborderDetails: [],
  });

  const handleSelect = (key, value, index = null) => {
    setFormData((prevFormData) => {
      if (key === 'suborderDetails' && index !== null) {
        const updatedSuborders = [...prevFormData.suborderDetails];
        updatedSuborders[index] = value;

        return {
          ...prevFormData,
          suborderDetails: updatedSuborders,
        };
      }
      return {
        ...prevFormData,
        [key]: value,
      };
    });

    setFormErrors((prevErrors) => {
      if (key === 'suborderDetails' && index !== null) {
        const updatedSuborderErrors = [...prevErrors.suborderDetails];
        updatedSuborderErrors[index] = '';
        return {
          ...prevErrors,
          suborderDetails: updatedSuborderErrors,
        };
      }

      return {
        ...prevErrors,
        [key]: '',
      };
    });
  };

  const validateForm = () => {
    let errors = {
      invoiceFiles: '',
      invoiceDate: '',
      shippingCost: '',
      platformFee: '',
      otherFee: '',
      suborderDetails: [],
    };
    let isValid = true;

    if (formData?.invoiceFiles.length === 0) {
      errors.invoiceFiles = 'Invoice file is required.';
      isValid = false;
    }
    if (!formData.invoiceDate) {
      errors.invoiceDate = 'Invoice date is required.';
      isValid = false;
    }
    if (!formData.shippingCost || parseFloat(formData.shippingCost) < 0) {
      errors.shippingCost = 'Valid shipping cost is required.';
      isValid = false;
    }

    if (!formData.platformFee || parseFloat(formData.platformFee) < 0) {
      errors.platformFee = 'Valid platform fee is required.';
      isValid = false;
    }

    if (!formData.otherFee || parseFloat(formData.otherFee) < 0) {
      errors.otherFee = 'Valid other fee is required.';
      isValid = false;
    }

    formData.suborderDetails.forEach((item, index) => {
      if (!item.unitCost || isNaN(item.unitCost) || parseFloat(item.unitCost) <= 0) {
        errors.suborderDetails[index] = 'Valid unit cost is required.';
        isValid = false;
      }
    });

    setFormErrors(errors);
    return isValid;
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileList = files.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file), // Generate URL for preview
      file: file,
    }));

    handleSelect('invoiceFiles', fileList); // Pass the entire new file list
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = formData.invoiceFiles.filter((_, index) => index !== indexToDelete);
    handleSelect('invoiceFiles', updatedFiles);
  };

  const resetState = () => {
    setFormData({
      invoiceFiles: [],
      invoiceDate: '',
      shippingCost: '',
      platformFee: '',
      otherFee: '',
      suborderDetails: [],
    });
  };

  return {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    handleSelect,
    handleFileChange,
    deleteFile,
    resetState,
  };
};
