import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../PatientGoal/PatientGoal.scss';
import CoreDropDown from '../CoreDropDown';
import { Panel, PanelGroup } from 'rsuite';
import { Popover, Whisper, Modal, Button, Dropdown } from 'rsuite';

import { addNew, dotsthree, edit, filters, goaldisableIcon, goalenableIcon, search } from 'res/images';
import { useNavigate, useParams } from 'react-router-dom';
import useApiManager from 'networking/ApiManager';
import { dateFormat, dateFormatString } from '../Helper';
import { subDays } from 'rsuite/esm/internals/utils/date';
import GoalFilterModal from '../GoalFilterModal';
import Loader from '../Loader';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { useDispatch } from 'react-redux';
import { setAlerts } from 'redux/Slices';
import { CustomActivityGoalAdd } from 'whealth-core-web/forms';
import EmptyView from '../EmptyView';
import CoreInputBox from '../CoreInputBox';

function PatientGoal(props) {
  const { newGoal, getGraphData } = props;
  const navigate = useNavigate();

  const ref = useRef();

  const ApiClient = useApiManager();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState([]);
  const [open, setOpen] = useState(false);

  const [goalData, setGoalData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filterData, setFilterData] = useState({
    status: 'draft,enable',
    adherence: 'Very Low,Low,Medium,High,Very High',
    date_range: dateFormatString(new Date()),
  });
  const [searchInput, setSearchInput] = useState('');
  const searchValue = useRef('');

  let timeOut;
  const dispatch = useDispatch();

  useEffect(() => {
    timeOut = setTimeout(() => {
      searchGoals();
    }, 300);
  }, [id, filterData, searchInput]);

  const applyFilter = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  const predefinedBottomRanges = [
    {
      title: 'Overall',
      id: dateFormatString(new Date()),
    },
    {
      title: 'Last 7 days',
      id: dateFormatString(subDays(new Date(), 6)),
    },
    {
      title: 'Last 14 days',
      id: dateFormatString(subDays(new Date(), 13)),
    },
    {
      title: 'Last 30 days',
      id: dateFormatString(subDays(new Date(), 29)),
    },
  ];

  const searchGoals = (search_str = searchInput) => {
    const params = { ...filterData, search_str, source: 'dashboard', page: 1 };
    setIsLoading(true);
    ApiClient.searchPatientGoals(id, params)
      .then((res) => {
        if (search_str == searchValue.current) {
          setTotalPages(1);
          setIsLoading(false);
          setGoalData(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateGoalsData = (goalid, status) => {
    const params = { status: status };
    ApiClient.updatePatientGOAL(id, params, goalid?.id)
      .then((res) => {
        getGraphData();
        searchGoals();
      })
      .catch((err) => {
        if (err.response.status == '500') {
          dispatch(setAlerts({ patient: { title: 'Internal server error', type: 'alert-danger' } }));
        } else {
          dispatch(setAlerts({ patient: { title: err.response.data.detail, type: 'alert-danger' } }));
        }
      });
  };

  const enableItem = (goalid) => {
    const windowConfirm = window.confirm('Are you sure you want to enable this ?');
    if (windowConfirm) {
      updateGoalsData(goalid, 'enable');
    }
  };

  const disableItem = (goalid) => {
    const windowConfirm = window.confirm('Are you sure you want to disable this ?');
    if (windowConfirm) {
      updateGoalsData(goalid, 'disable');
    }
  };

  const leftSideData = [
    {
      title: 'themeColor boldFont',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
    },
  ];
  const renderPatientTopBar = () => {
    return (
      <div className="d-flex justify-content-between align-items-center PatientTopBar">
        <button type="button" className="btn  addgoal d-flex  align-items-center " onClick={newGoal}>
          <span class="material-icons-outlined">add</span> Add Goal
        </button>
        <div className="d-flex">
          <div className="me-2">
            <CoreInputBox
              placeholder="Search Goal"
              value={searchInput}
              setValue={(text) => {
                clearTimeout(timeOut);
                searchValue.current = text;
                setSearchInput(text);
              }}
              leftIcon={search}
            />
          </div>
          <CoreDropDown
            data={predefinedBottomRanges}
            value={filterData.date_range}
            setValue={(vlue) => {
              filterData.date_range = vlue;
              setFilterData({ ...filterData });
            }}
            placeholder="Select"
          />
          <div className="goalfilterBtn ms-2">
            <div
              className=""
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight1"
              aria-controls="offcanvasRight1"
            >
              <div className={`d-flex align-items-center filterBtn btnParentDiv themeColor boldFont`}>
                <img src={filters} className="btnImg" />
                <div>Apply Filter</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    return (
      <div className="goalPopover">
        <Popover ref={ref} {...rest} full>
          <Dropdown.Menu onSelect={onSelect}>
            {rest.item.status == 'enable' && (
              <Dropdown.Item className="dropdown-items-popover custom-width" eventKey={1}>
                <span>
                  <img src={addNew} />
                </span>
                Add Activity
              </Dropdown.Item>
            )}
            {(rest.item.status == 'draft' || rest.item.status == 'enable') && (
              <Dropdown.Item className="dropdown-items-popover custom-width" eventKey={2}>
                <span>
                  <img src={edit} />
                </span>
                Edit
              </Dropdown.Item>
            )}
            {rest.item.status == 'disable' || rest.item.status == 'draft' ? (
              <Dropdown.Item className="dropdown-items-popover custom-width" eventKey={3}>
                <span>
                  <img className="" src={goaldisableIcon} />
                </span>
                Enable
              </Dropdown.Item>
            ) : (
              <Dropdown.Item className="dropdown-items-popover custom-width" eventKey={4}>
                <span>
                  <img className="" src={goalenableIcon} />
                </span>
                Disable
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Popover>
      </div>
    );
  });

  const renderPopOver = (icon, item) => {
    let itemsClickFunctions = {
      1: setOpen,
      2: () =>
        navigate(`/patient/${id}/goals/${item.id}/edit`, {
          state: {
            item,
          },
        }),
      3: enableItem,
      4: disableItem,
    };

    function handleSelectMenu(eventKey) {
      itemsClickFunctions[eventKey](item);
      ref.current.close();
    }
    return (
      <Whisper
        placement="auto"
        controlId="control-id-with-dropdown"
        trigger="click"
        ref={ref}
        speaker={<MenuPopover onSelect={handleSelectMenu} item={item} />}
      >
        <Button>
          <img src={icon} />
        </Button>
      </Whisper>
    );
  };

  const renderPatientGoals = (item) => {
    return <div className="ms-2">{renderPopOver(dotsthree, item)}</div>;
  };

  const renderPanelHeader = (item) => {
    return (
      <div className="PanelHeadertitle">
        <strong>{item?.goal_category}</strong> ({item.count}){' '}
        <span
          className={
            item.category_adherence <= 40
              ? 'low'
              : item.category_adherence > 40 && item.category_adherence <= 60
              ? 'middle'
              : 'high'
          }
        >
          {item.category_adherence}%
        </span>
      </div>
    );
  };

  const handleModalClose = () => {
    searchGoals();
    setOpen(false);
  };

  const customGoalActivityModal = () => {
    return (
      <div>
        <Modal backdrop="static" size="md" open={open} onClose={() => handleModalClose()}>
          <Modal.Header />
          <CustomActivityGoalAdd
            getGraphData={getGraphData}
            data={open}
            handleSubmit={handleModalClose}
            setLoading={setIsLoading}
          />
        </Modal>
      </div>
    );
  };

  const renderPatientAccordian = () => {
    return (
      <>
        <PanelGroup accordion defaultActiveKey={1} className="patientGoal-collapsible">
          {goalData?.length > 0 ? (
            goalData?.map((item, index) => {
              return (
                <Panel key={index} header={renderPanelHeader(item)} eventKey={index + 1} id={'panel' + (index + 1)}>
                  <div className="d-flex patientGoal-body flex-wrap justify-content-start">
                    {item?.data?.map((item1, index1) => {
                      return (
                        <div key={index1} className="patientGoal-card d-flex mb-3  mx-1">
                          <div className="w-100 d-flex align-items-center justify-content-between ">
                            <div className="">
                              <div
                                className={`pie-wrapper progress-${
                                  item1?.adherence?.percentage == 0
                                    ? 0
                                    : item1?.adherence?.percentage > 0 && item1?.adherence?.percentage <= 10
                                    ? 10
                                    : item1?.adherence?.percentage > 10 && item1?.adherence?.percentage <= 20
                                    ? 20
                                    : item1?.adherence?.percentage > 20 && item1?.adherence?.percentage <= 30
                                    ? 30
                                    : item1?.adherence?.percentage > 30 && item1?.adherence?.percentage <= 40
                                    ? 35
                                    : item1?.adherence?.percentage > 40 && item1?.adherence?.percentage < 50
                                    ? 40
                                    : item1?.adherence?.percentage == 50
                                    ? 50
                                    : item1?.adherence?.percentage > 50 && item1?.adherence?.percentage <= 60
                                    ? 60
                                    : item1?.adherence?.percentage > 60 && item1?.adherence?.percentage <= 70
                                    ? 70
                                    : item1?.adherence?.percentage > 70 && item1?.adherence?.percentage <= 80
                                    ? 80
                                    : item1?.adherence?.percentage > 80 && item1?.adherence?.percentage <= 90
                                    ? 90
                                    : item1?.adherence?.percentage > 90 && item1?.adherence?.percentage <= 99
                                    ? 95
                                    : 100
                                } style-2 me-2 pie-wrapper-3`}
                              >
                                <span class="label">
                                  {item1?.adherence?.percentage || 0}
                                  <span class="smaller">%</span>
                                </span>
                                <div class="pie">
                                  <div class="left-side half-circle"></div>
                                  <div class="right-side half-circle"></div>
                                </div>
                                <div class="shadow"></div>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <div
                                  onClick={() => navigate(`/patient/${id}/goal/${item1?.id}/show`)}
                                  className="title pointer"
                                >
                                  {item1?.goal?.title}
                                </div>
                                <div className="d-flex align-items-center">
                                  {/* <span
                                  class='material-icons-outlined'
                                  onClick={() => navigate(`/patient/${id}/goal/${item1?.id}/show`)}
                                >
                                  mode_edit
                                </span> */}
                                  {renderPatientGoals(item1, index1)}
                                </div>
                              </div>
                              <div>
                                {item1?.activity?.value ? item1?.activity?.value + '/' : null}
                                <strong>
                                  {item1?.activity?.target_value} {item1?.goal?.measuring_event?.measuring_unit}
                                </strong>
                              </div>
                              <div className="d-flex justify-content-between align-items-center patientGoal-subtext">
                                <div>{item1.frequency} </div>
                                <div className="goalMsg-text">
                                  {item1?.status == 'disable' ? (
                                    <span className="badge capitalize enrolled">Disabled</span>
                                  ) : item1?.status == 'draft' ? (
                                    <span className="badge capitalize lightGrayBadge">Draft</span>
                                  ) : (
                                    'Edited On : ' + dateFormat(item1?.updated_at, 'numeric', 'numeric', 'numeric')
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Panel>
              );
            })
          ) : (
            <EmptyView />
          )}
        </PanelGroup>
      </>
    );
  };

  const showFilters = useCallback(() => {
    return <GoalFilterModal getData={applyFilter} data={filterData} />;
  }, [filterData]);

  return (
    <div className="PatientGoal-part mt-4">
      {renderPatientTopBar()}
      {renderPatientAccordian()}
      {customGoalActivityModal()}

      {showFilters()}
      <Loader show={isLoading} />
    </div>
  );
}

export default PatientGoal;
