import React, { useEffect, useState, useRef } from 'react';
import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { useNavigate } from 'react-router-dom';
import CoreSearchDropDown from '../CoreSearchDropDown';
import { useDispatch } from 'react-redux';
import { setManualOrderId, setManualPatient, setPatientAddress } from 'redux/Slices';

function AddInvoicePayouts({ index }) {
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const value = useRef();

  const searchOrders = () => {
    ApiClient.searchOrders({ search_str: inputValue, status: 'partially_confirmed,confirmed' })
      .then((res) => {
        const modifiedData = res.data.map((order, index) => ({
          ...order,
          id: order?.order_id,
          title: order?.order_id,
          full_name: order?.order_id,
          index: index,
        }));

        if (value.current === inputValue) {
          setData(modifiedData);
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  useEffect(() => {
    if (activeData.length > 0) {
      sessionStorage.setItem(
        'ordersFilterData',
        JSON.stringify({
          page: 1,
          status: ['partially_confirmed', 'confirmed'],
          date_range: '',
          source: '',
          min_price: '',
          max_price: '',
          search_str: inputValue,
        })
      );

      const idStr = showData[0]?.order_id;
      const orderId = Number(idStr.substring(6));
      const userAddress = showData[0]?.user?.address;
      const user = showData[0]?.user;

      dispatch(setManualOrderId(orderId));
      dispatch(setPatientAddress(userAddress));
      dispatch(setManualPatient(user));

      if (showData[0]?.order_status === 'draft') {
        navigate(
          `/orders/${showData[0]?.index}/${orderId}/${showData[0]?.user?.id}/${showData[0]?.order_status}/${orderId}`,
          {
            state: { orderStatus: showData[0]?.order_status, patientName: showData[0]?.user?.full_name, orderId },
          }
        );
      } else {
        navigate(`/orders/${showData[0]?.index}/${orderId}/${showData[0]?.user?.id}`);
      }
    }
  }, [activeData]);

  const showTree = [
    { iconClass: 'receipt', title: 'Invoice', link: '/invoices' },
    {
      title: 'Add Invoice',
      icon: '',
      link: '',
    },
  ];
  const showTree1 = [
    { iconClass: 'credit_card', title: 'Payout', link: '/payouts' },
    {
      title: 'Add Payout',
      icon: '',
      link: '',
    },
  ];

  const renderHeader = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader showTree={index ? showTree1 : showTree} iconTitle="receipt" title="Invoice" />
      </div>
    );
  };

  const showOrders = () => (
    <div className="mb-4">
      <div className="articleHeader stickyHeader">
        {renderHeader()}
        <div className="hrLine mb-3" />
        <div className="w-40 mb-3 p-4">
          <div style={{ padding: 10, fontWeight: 500, fontSize: 16 }}>
            Order ID<sup>*</sup>
          </div>
          <div className="card w-100 no-border">
            <CoreSearchDropDown
              isRightIconref
              setTimeOutData={() => searchOrders()}
              inputValue={inputValue}
              inputStyle={{ height: '39px', width: '100%' }}
              data={data}
              setData={setData}
              setInputValue={(data) => {
                value.current = data;
                setInputValue(data);
              }}
              dropDownStyle={{ left: '10px', right: '10px' }}
              setShowData={setShowData}
              setActiveData={setActiveData}
              showData={showData}
              activeData={activeData}
              placeholder="Search by Order ID"
            />
          </div>
          {inputValue.length > 2 && data.length == [] && (
            <div className="mt-2" style={{ textAlign: 'left' }}>
              No Data Found
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 20,
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
          }}
        >
          <div className="hrLine mb-3" />
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="reusableBtnActive reusableBtn"
            style={{
              paddingLeft: 18,
              paddingRight: 18,
              paddingTop: 14,
              paddingBottom: 14,
              width: 120,
              alignSelf: 'flex-end',
            }}
          >
            Back
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="wrapper">{showOrders()}</div>
    </>
  );
}

export default AddInvoicePayouts;
