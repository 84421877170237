import React, { useState, useEffect } from 'react';
import { profileImage, dateFormat, timeFormat, teamRoles, dayTime } from '../Helper';
import { useGetPrescriptionListQuery } from 'redux/dashboardApis';
import Loader from '../Loader';
import { setLatestPrescription } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';

const PatientPrescription = ({ patientId }) => {
  const [loading, setLoading] = useState(true);
  const prescriptionListQuery = useGetPrescriptionListQuery({ id: patientId });
  const prescriptionList = prescriptionListQuery?.data?.data || [];
  const dispatch = useDispatch();
  useEffect(() => {
    if (prescriptionListQuery?.data) {
      setLoading(false);
    }
  }, [prescriptionListQuery]);

  const prescription = prescriptionList.find((item) => item.status === 'published');
  dispatch(setLatestPrescription(prescription));
  const showDosageTimes = (arr) => {
    return arr.map((item, index) => {
      const objectKeys = Object.keys(item);
      return (
        <div key={index} className={`badge badge-pill me-3 ${item[objectKeys[1]]}`}>
          <img src={item[objectKeys[0]]} className='icon' alt='icon' /> {objectKeys[0]}
        </div>
      );
    });
  };

  const renderListDataMedicine = (medicinesData) => {
    return (
      medicinesData?.prescription_medicines &&
      medicinesData.prescription_medicines.map((item, index) => {
        return (
          <div key={index} className='p-2 mt-3 mileStone rounded'>
            <div className='d-flex justify-content-between '>
              <div className='cardHeading pointer'>
                <span className='semiBold capitalize'>{item?.medicine?.medicine_title_repr || item?.product?.product_name}</span>
                <div className='capitalize'>{item?.medicine?.medicine_category || item?.product?.category}</div>
              </div>
              <div className='d-flex'>
                <div className='date me-2 '>
                  {item?.updated_at
                    ? dateFormat(item?.updated_at) + ' · ' + timeFormat(item?.updated_at)
                    : 'No Activity'}
                </div>
              </div>
            </div>
            {!item.is_optional ? (
              <div className='tagsHolder mt-2'>{showDosageTimes(dayTime(item.dosage))}</div>
            ) : (
              <div className='badge badge-light me-3 text-black mt-2'>As Needed</div>
            )}
            {!item.is_optional && (
              <div className='progressBar  mt-2 d-flex align-items-center'>
                <div className='progress  w-100 flex-fill '>
                  <div
                    className='progress-bar '
                    role='progressbar'
                    aria-valuenow='0'
                    style={{
                      width: parseInt((item?.activity?.activity_number / item?.duration) * 100) + '%',
                    }}
                    aria-valuemin='0'
                    aria-valuemax={item?.activity?.activity_number}
                  ></div>
                </div>
                <div className='progress-value '>
                  {item?.activity && item?.activity?.activity_number ? item?.activity?.activity_number : '0'}/
                  {item?.duration} Days
                </div>
              </div>
            )}
          </div>
        );
      })
    );
  };

  if (loading) {
    return <Loader show={loading} />;
  }

  if (prescriptionList.length === 0) {
    return <div>No prescriptions found for this patient.</div>;
  }

  return (
    <div className='cardDataBorder'>
      <div className='d-flex'>
        <div className='w-100'>
          <div className='cardMilestone'>
            <div className='headingMilestone d-flex justify-content-between'>
              <div className='d-flex'>
                <div>
                  <b>Assigned On &nbsp; &nbsp;</b>
                  {prescription?.created_at
                    ? `${dateFormat(prescription?.created_at)} · ${timeFormat(prescription?.created_at)}`
                    : '-- --'}
                </div>
                <div className='d-block ms-2'>
                  <span className={`badge bg-info capitalize ${prescription?.status}`}>{prescription?.status}</span>
                </div>
              </div>
            </div>
            <div className='d-flex mt-1 mb-3 justify-content-between align-items-center'>
              <div className='d-flex'>
                <div className='me-2'>
                  <img
                    className='rounded coachImage float-start img-fluid'
                    src={profileImage(prescription?.admin?.gender, prescription?.admin?.profile_url)}
                    alt='profile'
                  />
                </div>
                <div className='w-75 '>
                  <h5 className='coachtext capitalize'>{prescription?.admin?.full_name}</h5>
                  <p className='zeefitness capitalize'>{teamRoles(prescription?.admin?.role)}</p>
                </div>
              </div>
              <div>
                {prescription?.department && prescription?.department?.name ? (
                  <div className='badge capitalize lightGrayBadge'>Department {prescription?.department?.name}</div>
                ) : null}
              </div>
            </div>
            <div>
              {prescription?.doctor && Object.keys(prescription.doctor).length > 0 ? (
                <div className='w-100 mb-2'>
                  <span className='semiBold capitalize'>
                    Doctor ·
                    <div>
                      <span className='prescriptiontime'>{prescription.doctor.full_name} </span>
                    </div>
                  </span>
                </div>
              ) : null}
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-3'>
                <span className='semiBold'>Diagnosis · </span>
                <span className='prescriptiontime'>
                  {prescription?.diagnosis?.split('\n').map((line, index) => {
                    return (
                      <p className='firstletter_capitalize' key={index}>
                        {line}
                      </p>
                    );
                  })}
                </span>
              </div>
            </div>
            {prescription?.data && Object.keys(prescription.data).length > 0 && (
              <div className='w-100 mb-2'>
                <span className='semiBold'>Data · </span>
                <span className='prescriptiontime'>{prescription?.data}</span>
              </div>
            )}
            {prescription?.prescription_medicines && prescription?.prescription_medicines?.length > 0 ? (
              <div className='d-flex'>
                <div className='newMilestone warning w-100'>
                  <div className='badge badge-light me-3 text-black'>Medical Prescription</div>
                  {renderListDataMedicine(prescription)}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className='d-flex mt-3'>
        <div className='w-100'>
          <span className='semiBold '>Advice ·</span>
          <span className='prescriptiontime'>
            {prescription?.advice?.split('\n').map((line, index) => {
              return (
                <p className='firstletter_capitalize' key={index}>
                  {line}
                </p>
              );
            })}
          </span>
        </div>
      </div>
      <div className='d-flex mt-2'>
        <div className='w-100'>
          <span className='semiBold'>Notes For Team ·</span>
          <span className='prescriptiontime'>
            {prescription?.note?.split('\n').map((line, index) => {
              return (
                <p className='firstletter_capitalize' key={index}>
                  {line}
                </p>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PatientPrescription;