import React, { useEffect, useRef, useState } from 'react';
import { pdfIcon } from 'res/images';
import useApiManager from 'networking/ApiManager';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import CoreDatePicker from '../CoreDatePicker';
import { Stack } from 'rsuite';
import { UploadIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { redCrossDelete } from 'res/images';
import './InvoiceModal.css';
import { formatDate } from './utils/commonHelpers';
import { useInvoiceForm } from './hooks/useInvoiceForm';
import { InvoiceModalHeader } from './components/InvoiceModalHeader';

function CreateInvoiceModal({ vendorOrder, callOrders }) {
  const ApiClient = useApiManager();
  const modalRef = useRef(null);

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    handleSelect,
    deleteFile,
    handleFileChange,
    resetState,
  } = useInvoiceForm();

  useEffect(() => {
    if (vendorOrder?.vendorOrderDetails?.order_items) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        suborderDetails: vendorOrder.vendorOrderDetails.order_items.map((item) => ({
          medicineId: item?.id || '',
          medicineName: item?.product_item?.product?.product_name || '',
          medicineVariation:
            `${item?.product_item?.variation?.type} of ${item?.product_item?.variation?.variation}` || '',
          quantity: item?.quantity || '',
          unitCost: item?.item_amount || '',
          unitCostError: '',
        })),
      }));
    }
  }, [vendorOrder]);

  const getCreateFormBody = () => {
    const formDataBody = new FormData();
    formDataBody.append('order', vendorOrder?.vendorOrderDetails?.order_items[0]?.order);
    formDataBody.append('invoice_date', formatDate(formData.invoiceDate));
    formDataBody.append('shipping_cost', formData.shippingCost);
    formDataBody.append('platform_fee', formData.platformFee);
    formDataBody.append('other_fee', formData.otherFee);

    const invoiceItems = formData.suborderDetails.map((item) => ({
      order_item: item.medicineId,
      quantity: item.quantity,
      vendor_amount: item.unitCost,
    }));
    formDataBody.append('invoice_items', JSON.stringify(invoiceItems));

    formData.invoiceFiles.forEach(({ file }) => {
      if (file) formDataBody.append('files', file);
    });

    return formDataBody;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    let modalDiv = document.getElementById('InvoiceModalContainer');
    try {
      const formDataBody = getCreateFormBody();

      const apiCall = ApiClient.createInvoice(formDataBody);

      apiCall
        .then(() => {
          toast.success('Invoice generated successfully');
          callOrders();
        })
        .catch((error) => {
          toast.error('Error Submitting Invoice');
          console.error('Error submitting form:', error);
        });
    } finally {
      modalDiv?.click();
    }
  };

  const renderFilters = () => {
    return (
      <div style={{ padding: 25 }}>
        <div>
          <label htmlFor="invoice" style={{ display: 'block', color: '#434d56' }}>
            Upload Invoice*
          </label>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              id="invoice"
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              multiple
              onChange={handleFileChange} // Handle file selection
              style={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                zIndex: 1,
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                backgroundColor: 'white',
                color: '#6b7280',
                minHeight: '42px',
              }}
            >
              <span>Upload Invoice</span>
              <UploadIcon
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#6b7280',
                }}
              />
            </div>
          </div>
          {formErrors.invoiceFiles && <p className="inputMsg">{formErrors.invoiceFiles}</p>}

          {/* Display uploaded files */}
          {formData.invoiceFiles && (
            <div className="uploadReportData">
              {formData.invoiceFiles.map((file, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <img
                        className="showImageStyle"
                        src={pdfIcon}
                        alt="PDF Icon"
                        style={{ width: '24px', marginRight: '8px' }}
                      />
                      <a href={file.url} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                        {file.name}
                      </a>
                    </div>
                    <div
                      onClick={() => {
                        deleteFile(index);
                      }}
                    >
                      <img src={redCrossDelete} alt="PDF Icon" style={{ width: '24px', height: '24px' }} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="mt-2" id="invoiceDatePickerContainer">
          <Stack direction="column" spacing={8} alignItems="auto">
            <CoreDatePicker
              label={'Enter Invoice Date*'}
              showMSG={formErrors.invoiceDate}
              calanderProps={{
                ranges: [
                  {
                    label: 'today',
                    value: new Date(),
                  },
                ],
                value: formData.invoiceDate,
                onClean: () => {},
                preventOverflow: true,
                placeholder: 'Enter Invoice Date',
                onChange: (data) => {
                  handleSelect('invoiceDate', data);
                },
                placement: '',
                calendarDefaultDate: new Date(),
                onOk: (data) => {},
                style: { width: '100%', padding: 0, margin: 0 },
                container: () => document.getElementById('invoiceDatePickerContainer'),
                className: 'custom-datepicker',
              }}
            />
          </Stack>
        </div>

        <div className="mt-2">
          <CoreInputBox
            inputStyle={{}}
            setErrors={() => {}} //check
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Enter Shipping Cost"
            placeholder="Enter Shipping Cost"
            value={`${formData.shippingCost}`}
            showMSG={formErrors.shippingCost}
            setValue={(data) => {
              handleSelect('shippingCost', data);
            }}
            validatedecimal
          />
        </div>

        <div className="mt-2">
          <CoreInputBox
            inputStyle={{}}
            setErrors={() => {}} //check
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Enter Platform Fee"
            placeholder="Enter Platform Fee"
            value={`${formData.platformFee}`}
            showMSG={formErrors.platformFee}
            setValue={(data) => {
              handleSelect('platformFee', data);
            }}
            validatedecimal
          />
        </div>

        <div className="mt-2">
          <CoreInputBox
            inputStyle={{}}
            setErrors={() => {}} //check
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Enter Other Cost"
            placeholder="Enter Other Cost"
            value={`${formData.otherFee}`}
            showMSG={formErrors.otherFee}
            setValue={(data) => {
              handleSelect('otherFee', data);
            }}
            validatedecimal
          />
        </div>

        <div
          style={{
            padding: '24px',
            border: '1px solid #e5e7eb',
            borderRadius: '12px',
          }}
          className="mt-4"
        >
          {/* Header Row */}

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 1fr 2fr',
              fontWeight: 500,
              color: '#111827',
            }}
          >
            <div>Medicine</div>
            <div>Quantity</div>
            <div>
              Vendor Amount*
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#6b7280' }}>per unit</div>
            </div>
          </div>

          {/* Medicine Items */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {/* Item 1 */}

            {formData?.suborderDetails?.map((item, index) => (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr 2fr',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '24px',
                  borderTop: '1px solid #e5e7eb',
                }}
              >
                <div>
                  <div style={{ fontWeight: 500, color: '#111827' }}>{item.medicineName}</div>
                  <div style={{ color: '#6b7280' }}>{item.medicineVariation}</div>
                </div>
                <div style={{ color: '#111827' }}>{item.quantity}</div>
                <div>
                  <CoreInputBox
                    inputStyle={{}}
                    setErrors={() => {}} //check
                    labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                    label=""
                    placeholder=""
                    value={`${item.unitCost}`}
                    showMSG={formErrors.suborderDetails[index]}
                    setValue={(data) => {
                      handleSelect(
                        'suborderDetails',
                        {
                          ...item,
                          unitCost: data,
                        },
                        index
                      );
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <CoreButton
          onClick={handleSubmit}
          customClass="btn btn-secondary btn-lg"
          title={'Generate Invoice'}
          customDivStyle={{ width: '40%', margin: '0 auto' }}
        />
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div className="filterModal">
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-2"
          id="invoiceModal"
          aria-labelledby="invoiceModalLabel"
          ref={modalRef}
        >
          <div className="whiteBackground" style={{ width: '550px' }}>
            <InvoiceModalHeader title={'Generate Invoice'} handleClose={() => resetState()} />
            <div className="filterHeaderData">{renderFilters()}</div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default CreateInvoiceModal;
