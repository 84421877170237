import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { ChipsTagsView, Select2DropDown } from './index';
import { Stack } from 'rsuite';

function InvoiceFilter(props) {
  const { filterData, setFilterData, api, title } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [showData, setShowData] = useState([]);

  useEffect(() => {
    let stringActiveData = activeData?.toString();
    let obj = { vendor_data: showData };
    obj['vendor_ids'] = stringActiveData;
    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setShowData(filterData?.['vendor_data'] || []);
    const tempActiveData = filterData['vendor_ids']?.split(',').map((item) => item && parseInt(item));
    setActiveData(tempActiveData || []);
  }, [filterData]);

  useEffect(() => {
    searchVendors();
  }, []);

  const searchVendors = () => {
    ApiClient.getAllVendors()
      .then((res) => {
        console.log('inside useEffect', res.data);
        const modifiedData = res.data.map((vendor) => ({
          ...vendor,
          full_name: vendor?.vendor_name,
        }));

        setData(modifiedData);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  const renderChips = () => {
    return (
      <div>
        <div className="mt-3" />
        <ChipsTagsView
          setActiveTags={setActiveData}
          activeTags={activeData}
          data={showData}
          handleRoles
          setData={setShowData}
        />
      </div>
    );
  };

  const renderHeading = () => {
    return <div className="filterHeadings mt-3">{title}</div>;
  };

  const returnSearchDropDown = () => {
    return (
      <div className="mt-2 position-relative" id="vendorNamePickerContainer">
        <Stack direction="column" spacing={8} alignItems="auto">
          <Select2DropDown
            data={data}
            style={{ width: '100%' }}
            labelKey={'full_name'}
            valueKey={'id'}
            placeholder="Search Vendor Name"
            value={''}
            msgStyle={{ color: 'red' }}
            retuired
            container={() => document.getElementById('vendorNamePickerContainer')}
            onSelect={(itemID) => {
              let item = data.find((item) => item.id == itemID);
              let setDataIds = new Set([...activeData, item.id]);
              let hasData = showData.find((itemData) => item.id == itemData.id);

              if (!hasData) {
                setShowData([...showData, item]);
              }
              setActiveData([...setDataIds]);
            }}
            placement={'bottom'}
          />
        </Stack>
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {returnSearchDropDown()}
      {renderChips()}
    </div>
  );
}

export default InvoiceFilter;
