import { closeIcon } from 'res/images';
export const InvoiceModalHeader = ({ title, handleClose }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
      <div
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="InvoiceModalContainer"
        style={{
          backgroundColor: '#ECECEC',
          borderRadius: 15,
          padding: 1,
          alignSelf: 'flex-end',
          marginBottom: 10,
        }}
        onPress={handleClose}
      >
        <img src={closeIcon} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ fontWeight: 600, fontSize: 14 }}>{title}</p>
      </div>
    </div>
  );
};
