import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AdminPanelHeader } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { profileImage, timeFormat, dateFormat, titlizeString } from '../Helper';
import { Loader } from 'whealth-core-web/components';
import CoreInputBox from '../CoreInputBox';
import { location, blueEdit } from 'res/images';
import '../Apollo/RenderApolloTab.css';
import CoreButton from '../CoreButton';
import PayoutModal from '../PayoutModal';
import { ToastContainer } from 'react-toastify';

function ViewPayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const { orderId, payoutId, patientId } = useParams();
  const [payoutDetails, setPayoutDetails] = useState({});
  const [payoutData, setPayoutData] = useState(null);
  const [payoutLoading, setPayoutLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    getPayout();
  }, [orderId, refreshData]);

  const getPayout = () => {
    setPayoutLoading(true);
    ApiClient.getPayout({ id: payoutId })
      .then((res) => {
        setPayoutDetails(res?.data);
        setPayoutLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showTree1 = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      title: payoutDetails?.user_details?.full_name,
      icon: profileImage(payoutDetails?.user_details?.gender, payoutDetails?.user_details?.profile_url),
      link: `/patient/${patientId}/dashboard`,
    },
    {
      title: 'View Order',
      icon: '',
      link: '',
    },
    {
      title: 'View Payout',
      icon: '',
      link: '',
    },
  ];

  const renderSearchBox = () => {
    return (
      <div className="w-100">
        <CoreInputBox placeholder="Search a Order by Patient name or Order ID" setValue={(val) => {}} value={''} />
      </div>
    );
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: 'Partially Ordered',
      id: 'ordered_partially',
    },
    {
      title: 'Unfulfilled',
      id: 'in_progress',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
  ];

  const orderStatusWithColor = (ehr_order_status, isHeading) => {
    return (
      <div className={`d-flex align-items-center ${isHeading && `justify-content-end`}`}>
        <span
          className={`status-text align-items-center badge capitalize active align-items-center d-flex ${ehr_order_status}`}
        >
          {titlizeString(orderStatus.find((item3) => item3.id == ehr_order_status)?.title) || ehr_order_status}
        </span>
      </div>
    );
  };

  const renderOrders = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '85vh' }}>
        <div className="d-flex flex-row justify-content-between " style={{ width: '95%' }}>
          <div className=" d-flex justify-content-between" style={{ width: '100%' }}>
            <p style={{ position: 'absolute' }}>
              {orderStatusWithColor(payoutDetails?.order_details?.ehr_order_status, true)}
            </p>
            <div className="d-flex flex-col justify-content-between items-center space-x-4 order-modal">
              <div
                className="d-flex align-items-center"
                style={{ background: '#F9FAFE', padding: '10px', borderRadius: '8px', width: '50%' }}
              >
                <div className="mr-2 mt-4 text-capitalize" style={{ fontWeight: '500' }}>
                  <p>Source: {payoutDetails?.order_details?.source}</p>
                  <p>Sub-order: 1</p>
                  <p>Order ID : {payoutDetails?.order_details?.order_id}</p>
                </div>
                <div className="vl">.</div>
                <div className="mx-2 patient-name" style={{ fontWeight: 'bold', color: '#243b86' }}>
                  <img
                    className="listUserImage rounded-circle"
                    style={{ width: '20px', height: '20px', marginRight: '5px' }}
                    src={profileImage(payoutDetails?.user_details?.gender, payoutDetails?.user_details?.profile_url)}
                  />
                  {payoutDetails?.user_details?.full_name}
                </div>
                <div className="mx-2 patient-address d-flex">
                  <div>
                    <img src={location} alt="location" />
                  </div>

                  <div className="complete-address" style={{ fontWeight: '500' }}>
                    {payoutDetails?.order_details?.shipping_address}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="diet-regime cardDataBorder w-100 patientordertabtable fulfilled-suborder">
          <div className="table-suborder">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: 10,
              }}
            >
              <div
                data-bs-toggle={'offcanvas'}
                data-bs-target={'#payoutModal'}
                onClick={() => {
                  setPayoutData({
                    invoiceId: payoutDetails?.invoice,
                    vendorName: payoutDetails?.vendor_name,
                    payment_amount: payoutDetails?.payout_amount,
                    payment_type: payoutDetails?.payout_type,
                    reference_id: payoutDetails?.reference_id,
                    payout_reference_id: payoutDetails?.payout_reference_id,
                    status: payoutDetails?.status,
                  });
                }}
              >
                <img src={blueEdit} className="editModal" />
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>
                    <b>Vendor Name</b>
                  </th>
                  <th>
                    <b>Payout Amount</b>
                  </th>
                  <th>
                    <b>Payout Type</b>
                  </th>
                  <th>
                    <b>Reference ID</b>
                  </th>
                  <th>
                    <b>Payout Reference ID</b>
                  </th>
                  <th>
                    <b>Status</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="capitalize">
                    <div className="d-flex flex-column text-center">{payoutDetails?.vendor_name}</div>
                  </td>
                  <td>
                    <div>Rs. {payoutDetails?.payout_amount}</div>
                  </td>

                  <td>{payoutDetails?.payout_type}</td>
                  <td>{payoutDetails?.reference_id}</td>
                  <td>{payoutDetails?.payout_reference_id}</td>
                  <td>
                    <div className={`d-flex align-items-center justify-content-center`}>
                      <span
                        className={`status-text align-items-center badge capitalize active align-items-center d-flex ${
                          payoutDetails?.status === 'PENDING' ? `to_order` : `order_placed`
                        }`}
                      >
                        {payoutDetails?.status}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ marginTop: 'auto', marginBottom: '0px', width: '95%' }}>
          <div className="hrLine " style={{ background: '#D1DCFF' }} />
          <div className="d-flex flex-row" style={{ gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
            <CoreButton
              onClick={() => {
                navigate(-1);
              }}
              customClass="btn btn-secondary btn-lg"
              title="Go Back"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader
          searchBox={renderSearchBox}
          iconTitle="person_outline"
          showTree={showTree1}
          title="Patient"
          placeholder="Search Patients"
        />
      </div>
    );
  };

  return (
    <>
      <div className="articleHeader stickyHeader">
        {renderHeader1()}
        <div className="hrLine mb-3" />
      </div>
      <div>
        <div style={{ marginLeft: '230px' }} className="wrapperContent">
          {renderOrders()}
        </div>
        <PayoutModal isEdit={true} payoutData={payoutData} payoutId={payoutId} setRefreshData={setRefreshData} />
        <Loader show={payoutLoading} />
        <ToastContainer />
      </div>
    </>
  );
}

export default ViewPayout;
