import { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { dateFormat, exportToCsv, timeFormat } from '../../Helper';

export const usePayoutsData = ({ value, filterData, setFilterData }) => {
  const ApiClient = useApiManager();
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const defaultObj = {
    page: currentPage,
    date_range: '',
    vendor: '',
    order: '',
  };

  useEffect(() => {
    setFilterData({});
    sessionStorage.removeItem('payoutFilterData');
  }, []);

  useEffect(() => {
    getFilteredOrders();
  }, [currentPage, filterData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  useEffect(() => {
    searchOrders();
  }, [searchStr]);

  const createFilterParams = (filterData) => {
    const params = { ...defaultObj };
    if (filterData.date) {
      params.date_range = Array.isArray(filterData.date) ? '' : filterData.date;
    }
    if (filterData.vendor_ids) {
      params.vendor = filterData.vendor_ids;
    }
    return params;
  };

  const getStoredOrCurrentFilterData = () => {
    return JSON.parse(sessionStorage.getItem('payoutFilterData')) || filterData;
  };

  const searchOrders = async () => {
    setIsLoading(true);
    try {
      const data = getStoredOrCurrentFilterData();
      const params = createFilterParams(data);
      const res = await ApiClient.getFilteredPayouts(params);
      
      if (value.current === params.search_str) {
        setTotalPages(res.data.total_pages);
        setOrderList(res.data.results);
      }
    } catch (err) {
      console.warn('getOrders', err.response);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilteredOrders = async () => {
    setIsLoading(true);
    try {
      const data = getStoredOrCurrentFilterData();
      const params = createFilterParams(data);
      if (params.date_range) {
        params.date_range = String(params.date_range);
      }
      
      const res = await ApiClient.getFilteredPayouts(params);
      setTotalPages(res.data.total_pages);
      setOrderList(res.data.results);
    } catch (err) {
      console.log('getFilteredOrders err: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const exportCsv = async () => {
    setIsLoading(true);
    try {
      const res = await ApiClient.exportPayoutCsv();
      exportToCsv(res.data, `Payouts_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
    } catch (err) {
      console.warn('exportCsv', err.response);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterData = (data) => {
    setFilterData(data);
    sessionStorage.setItem('payoutFilterData', JSON.stringify(data));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilterData({});
    sessionStorage.removeItem('payoutFilterData');
  };

  return {
    orderList,
    isLoading,
    searchStr,
    setSearchStr,
    currentPage,
    setCurrentPage,
    totalPages,
    exportCsv,
    handleFilterData,
    clearFilters,
  };
}; 