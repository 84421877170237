import React from 'react';
import { useNavigate } from 'react-router-dom';

export const PayoutsTable = ({ orderList, isLoading }) => {
  const navigate = useNavigate();

  const renderStatus = (status) => {
    const statusClass = status === 'PENDING' ? 'to_order' : 'order_placed';
    return (
      <span
        className={`status-text align-items-center badge capitalize active justify-content-center ${statusClass} text-align-center`}
      >
        {status}
      </span>
    );
  };

  if (!orderList?.length && !isLoading) {
    return (
      <tr>
        <td colSpan={8} className="text-center">
          No data found
        </td>
      </tr>
    );
  }

  return orderList.map((item) => (
    <tr key={item?.order} style={{ height: '50px' }}>
      <td>{item?.order_details?.order_id}</td>
      <td>
        <b>{item?.vendor_name}</b>
      </td>
      <td>
        <b>{item?.payout_type}</b>
      </td>
      <td>{item?.invoice_details?.invoice_date}</td>
      <td>₹ {item?.payout_amount}</td>
      <td>{item?.reference_id}</td>
      <td>{renderStatus(item?.status)}</td>
      <td>
        <div
          onClick={() => navigate(`/payouts/${item?.order_details?.id}/${item?.user}/view-payout/${item?.id}`)}
          style={{ color: '#243B86', cursor: 'pointer' }}
        >
          View Details
        </div>
      </td>
    </tr>
  ));
};
