import React, { useEffect, useState } from "react";
import "./PatientDetails.css";
import Select2DropDown from "../common/Select2DropDown";
import CoreCheckBox from "../CoreCheckBox";
import CoreInputBox from "../CoreInputBox";
import { blueEdit, search } from "res/images";
import SearchBox from "../common/SearchBox";
import {
  useAddPatientAddressMutation,
  useGetPatientAddressMutation,
  useUpdatePatientAddressMutation,
} from "redux/dashboardApis";
import { useDispatch, useSelector } from "react-redux";
import { closeIcon } from "res/images";
import {
  setManualOrderSummary,
  setManualPatient,
  setPatientAddress,
  setPatientAddressStep1,
  setManualOrderItems,
  setPatientDetails,
  setPatientName,
} from "redux/Slices";
import { useParams } from "react-router-dom";
import useApiManager from "networking/ApiManager";

const PatientDetails = (props) => {
  const ApiClient = useApiManager();
  const { patientId } = useParams();
  const { setError, error, handleNext } = props;
  const [errors, setErrors] = useState({
    pincode: "",
    address_line1: "",
    city: "",
    state: "",
    address_type: "",
  });
  const inputFieldFormat = {
    pincode: {
      key: "pincode",
      placeholder: "Pincode",
      label: "Pincode",
      viewType: "text",
      message: errors?.pincode,
      maxLength: 6,
      validateNumbers: true,
    },
    address_line1: {
      key: "address_line1",
      placeholder: "Address Line 1",
      label: "Address Line 1",
      viewType: "text",
      message: errors?.address_line1,
    },
    city: {
      key: "city",
      placeholder: "City",
      label: "City",
      viewType: "text",
      message: errors?.city,
    },
    state: {
      key: "state",
      placeholder: "State",
      label: "State",
      viewType: "text",
      message: errors?.state,
    },
  };
  const [addPatientAddress, addPatientAddressResponse] =
    useAddPatientAddressMutation();
  const [updatePatientAddress, updatePatientAddressResponse] =
    useUpdatePatientAddressMutation();
  const [getPatientAddress, patientAddressData] =
    useGetPatientAddressMutation();
  const [patientSearch, setPatientSearch] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [availableAddressTypes, setAvailableAddressTypes] = useState([
    "home",
    "other",
  ]);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [patientApiDetails, setPatientApiDetails] = useState({});

  const patientDetails = useSelector(
    (state) => state?.sliceReducer?.patientManualDetails
  );
  const patientAddress = useSelector(
    (state) => state?.sliceReducer?.patientAddress
  );
  console.log("patientAddress", patientAddress);
  const [patient, setPatient] = useState(patientDetails);
  const [newAddress, setNewAddress] = useState({
    id: null,
    address_type: "home",
    address_line1: "",
    address_line2: "",
    city: "",
    pincode: "",
    state: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (patientId) {
      getPatientDetails(patientId);
      dispatch(setPatientAddress(null));
    }
  }, []);

  useEffect(() => {
    if (patientApiDetails && Object.keys(patientApiDetails).length > 0) {
      handleItemSelect(patientApiDetails);
    }
  }, [patientApiDetails]);

  const getPatientDetails = (id) => {
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setPatientApiDetails(res.data);
        setPatient(res.data);
      })
      .catch((err) => {
        console.log("getNotes error: ", err);
      });
  };

  const validateFields = () => {
    let hasErrors = false;
    let newErrors = { ...errors };

    if (!newAddress.address_line1) {
      newErrors.address_line1 = "Address Line 1 is required";
      hasErrors = true;
    } else {
      newErrors.address_line1 = "";
    }

    if (!newAddress.city) {
      newErrors.city = "City is required";
      hasErrors = true;
    } else {
      newErrors.city = "";
    }

    if (!newAddress.state) {
      newErrors.state = "State is required";
      hasErrors = true;
    } else {
      newErrors.state = "";
    }

    if (!newAddress.pincode || newAddress.pincode.length !== 6) {
      newErrors.pincode = "Valid pincode is required";
      hasErrors = true;
    } else {
      newErrors.pincode = "";
    }

    if (!newAddress.address_type) {
      newErrors.address_type = "Address type is required";
      hasErrors = true;
    } else {
      newErrors.address_type = "";
    }

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleSaveAddress = async () => {
    if (!validateFields()) {
      return;
    }

    if (newAddress.id) {
      if (patient.id) {
        let updatePatientAddressResponse = await updatePatientAddress({
          ...newAddress,
          patientId: patient.id,
        }).unwrap();
        if (updatePatientAddressResponse.status == 200) {
          getPatientAddresses(patient.id);
          setIsEditing(false);
        } else {
          setErrors(updatePatientAddressResponse.data.errors);
        }
      }
    } else {
      if (patient.id) {
        let addPatientAddressResponse = await addPatientAddress({
          ...newAddress,
          patientId: patient.id,
        }).unwrap();
        if (addPatientAddressResponse.status == 200) {
          getPatientAddresses(patient.id);
          setIsEditing(false);
        } else {
          setErrors(addPatientAddressResponse.data.errors);
        }
      }
    }

    setEditingAddressId(null);
    setNewAddress({
      id: null,
      address_type: "",
      address_line1: "",
      address_line2: "",
      city: "",
      pincode: "",
      state: "",
    });
  };

  useEffect(() => {
    if (patient?.id) {
      getPatientAddresses(patient?.id);
    }
  }, [patient?.id]);

  const handleEditAddress = (address) => {
    dispatch(setPatientAddressStep1("completed"));
    setIsEditing(true);
    setEditingAddressId(address.id);
    setNewAddress(address);

    const currentAddressTypes = addresses.map((addr) => addr.address_type);
    const availableTypes = ["home", "other"].filter(
      (type) =>
        !currentAddressTypes.includes(type) || type === address.address_type
    );
    setAvailableAddressTypes(availableTypes);
  };

  const handleItemSelect = async (item) => {
    dispatch(setManualPatient(item));
    setPatient(item);
    dispatch(setPatientAddress(null));
    setPatientSearch(false);
    dispatch(setManualOrderSummary(""));
    setError("");
  };

  const getPatientAddresses = async (patientId) => {
    try {
      let addressData = await getPatientAddress({ id: patientId }).unwrap();
      if (addressData.data.length > 0) {
        setAddresses([]);
        const homeAddresses = addressData.data
          .filter((address) => address.address_type === "home")
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

        const otherAddresses = addressData.data
          .filter((address) => address.address_type === "other")
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

        const selectedAddresses = [];
        if (homeAddresses.length > 0) {
          selectedAddresses.push(homeAddresses[0]);
        }
        if (otherAddresses.length > 0) {
          selectedAddresses.push(otherAddresses[0]);
        }
        setAddresses((prevAddresses) => [
          ...prevAddresses,
          ...selectedAddresses.map((address, index) => ({
            id: prevAddresses.length + index + 1,
            ...address,
          })),
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };

  const handleSelectAddress = (e) => {
    let radioaSelectedAddress = addresses.find(
      (address) => address.id == e.target.id
    );
    dispatch(setPatientAddress(radioaSelectedAddress));
    if (patientDetails?.id) {
      setError("");
    }
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <div className="patient-details">
      <div className="form-group">
        {!patient.id || !patientId ? (
          <SearchBox
            placeHolder="Search a patient by name or mobile number"
            searchMethod="PATIENTSEARCH"
            label="full_name"
            searchId="Patients"
            handleItemClick={handleItemSelect}
            defaultInputValue={""}
            // renderPath='/patient/{source_id}/dashboard' //ShowPatientProfile
            imagePath="profile_url"
          />
        ) : (
          <div
            style={{
              border: "1px solid #D9D9D9",
              padding: "12px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            <div>{`${patient.full_name} (${patient.phone})`}</div>
            <div
              onClick={() => {
                setPatient({});
                setAddresses([]);
                dispatch(setManualPatient({}));
                dispatch(setPatientAddress({}));
                dispatch(setManualOrderItems([]));
              }}
            >
              <img src={closeIcon} />
            </div>
          </div>
        )}
      </div>
      {patient.id &&
        (error && error ? (
          <div className="inputMsg" style={{ fontWeight: "500" }}>
            Select Address: {(error && error) || errors}
          </div>
        ) : (
          <label>
            <h7 className="mb-2 mt-4">Select Address:</h7>
          </label>
        ))}

      <div className="form-group d-flex" style={{ gap: "20px" }}>
        {addresses.length > 0 &&
          addresses.map((address) => {
            return (
              <div key={address.id} className="address-card">
                <input
                  type="radio"
                  id={address.id}
                  value={patientAddress?.id}
                  checked={patientAddress?.id === address.id}
                  name="address"
                  onChange={handleSelectAddress}
                />
                <label htmlFor={`address${address.id}`}>
                  <b>{capitalize(address.address_type)}</b>
                  <div>{address.address_line1}</div>
                  <div>{address.address_line2}</div>
                  <div>
                    {address.city}, {address.state}, {address.pincode}
                  </div>
                </label>
                <button
                  className="edit-btn"
                  onClick={() => handleEditAddress(address)}
                >
                  <img src={blueEdit} alt="edit" />
                </button>
              </div>
            );
          })}

        {(addresses.length < 2 || isEditing) && patient.id && (
          <div
            className="new-address"
            style={{ paddingLeft: "25px", paddingRight: "25px" }}
          >
            <CoreCheckBox
              className={"me-4"}
              id="addNewAddress"
              title={
                addresses.length == 0 || addresses.length == 1
                  ? "Add New Address"
                  : "Edit Address"
              }
              style={{ color: "#243B86", fontWeight: "600" }}
              checked={isEditing}
              onChange={() => {
                if (isEditing) {
                  setIsEditing(false);
                  setEditingAddressId(null);
                  setNewAddress({
                    id: null,
                    address_type: "",
                    address_line1: "",
                    address_line2: "",
                    city: "",
                    pincode: "",
                    state: "",
                  });
                } else {
                  setIsEditing(true);
                }
              }}
            />
            {isEditing && <div className="hrLine mb-3" />}

            {isEditing && (
              <div className="address-form">
                <CoreInputBox
                  // {...inputFieldFormat?.address_line1}
                  retuired
                  label="Address Line 1"
                  placeholder="Address"
                  labelStyle={{ fontWeight: "600" }}
                  value={newAddress?.address_line1 || ""}
                  setValue={(value) =>
                    setNewAddress({ ...newAddress, address_line1: value })
                  }
                  showMSG={errors.address_line1}
                />
                <CoreInputBox
                  label="Address Line 2"
                  placeholder="Address"
                  labelStyle={{ fontWeight: "600", marginTop: "8px" }}
                  value={newAddress?.address_line2 || ""}
                  setValue={(value) =>
                    setNewAddress({ ...newAddress, address_line2: value })
                  }
                />
                <CoreInputBox
                  {...inputFieldFormat?.city}
                  retuired
                  label="City"
                  placeholder="City"
                  labelStyle={{ fontWeight: "600", marginTop: "8px" }}
                  value={newAddress?.city || ""}
                  setValue={(value) =>
                    setNewAddress({ ...newAddress, city: value })
                  }
                  showMSG={errors.city}
                />
                <CoreInputBox
                  {...inputFieldFormat?.state}
                  retuired
                  label="State"
                  placeholder="State"
                  labelStyle={{ fontWeight: "600", marginTop: "8px" }}
                  value={newAddress?.state || ""}
                  setValue={(value) =>
                    setNewAddress({ ...newAddress, state: value })
                  }
                  showMSG={errors.state}
                />
                <CoreInputBox
                  {...inputFieldFormat.pincode}
                  label="Pincode"
                  retuired
                  placeholder="Type Pincode"
                  labelStyle={{ fontWeight: "600", marginTop: "8px" }}
                  value={newAddress?.pincode || ""}
                  setValue={(value) =>
                    setNewAddress({ ...newAddress, pincode: value })
                  }
                  maxLength={6}
                  type="number"
                  showMSG={errors.pincode}
                />
                <label className=" mb-2 mt-2" style={{ fontWeight: "600" }}>
                  <span className="requiredInput"> * </span>Type
                </label>
                <div>
                  {availableAddressTypes.map((type) => {
                    return (
                      <button
                        type="button"
                        onClick={() =>
                          setNewAddress({ ...newAddress, address_type: type })
                        }
                        className={`common-btn ${
                          newAddress.address_type === type ? "active" : ""
                        } text-capitalize`}
                      >
                        {type}
                      </button>
                    );
                  })}
                </div>
                <button className="save-btn" onClick={handleSaveAddress}>
                  Save Address
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
