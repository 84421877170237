import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterData, setFilterData] = useState({});
  const [orderFilterData, setOrderFilterData] = useState({});
  const [invoiceFilterData, setInvoiceFilterData] = useState({});
  const [payoutFilterData, setPayoutFilterData] = useState({});

  return (
    <FilterContext.Provider
      value={{
        filterData,
        setFilterData,
        orderFilterData,
        setOrderFilterData,
        invoiceFilterData,
        setInvoiceFilterData,
        payoutFilterData,
        setPayoutFilterData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
