import React, { useMemo, useRef, useState } from 'react';
import { DashBoardHeader } from 'components';
import { filterFunnel } from 'res/images';
import Loader from '../Loader';
import Pagination from '../Pagination';
import CommonAlert from '../CommonAlert';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FilterModal, FilterChips } from 'whealth-core-web/components';
import { useFilter } from 'whealth-core-web/components/FilterContext';
import { checkObjectLength } from 'whealth-core-web/components/Helper';
import { InvoicesTable } from './components/InvoicesTable';
import { InvoicesHeader } from './components/InvoicesHeader';
import { useInvoicesData } from './hooks/useInvoicesData';

function Invoices() {
  const navigate = useNavigate();
  const value = useRef('');
  const [alert, setAlert] = useState({});
  const [clearFiltersData, setClearFilterData] = useState(false);
  const { invoiceFilterData: filterData, setInvoiceFilterData: setFilterData } = useFilter();

  const {
    orderList,
    isLoading,
    searchStr,
    setSearchStr,
    currentPage,
    setCurrentPage,
    totalPages,
    exportCsv,
    handleFilterData,
    clearFilters,
  } = useInvoicesData({ value, filterData, setFilterData });

  const rightSideData = [
    {
      title: 'Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filterFunnel,
    },
  ];

  const renderDashBoardHeader = useMemo(() => <DashBoardHeader leftData={rightSideData} />, []);

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids'];
    if (!checkObjectLength(filterData, ignoreKeys)) {
      Object.keys(filterData).length > 0 && clearFilters();
      return null;
    }

    return (
      <div className="mt-2 pt-1">
        <FilterChips
          clearData={clearFilters}
          setData={setFilterData}
          data={filterData}
          sessionStorageKey="invoiceFilterData"
        />
      </div>
    );
  }, [filterData]);

  return (
    <div className="wrapper">
      <Loader show={isLoading} />
      <div className="mb-4">
        <div className="articleHeader stickyHeader">
          <InvoicesHeader
            searchStr={searchStr}
            setSearchStr={setSearchStr}
            setCurrentPage={setCurrentPage}
            value={value}
          />
          <div className="hrLine mb-3" />
        </div>
        <div className="px-4 wrapperContent">
          <div className="d-flex justify-content-end mb-4 gap-3">
            {renderFilterChips}
            <div className="d-flex flex-row">
              {renderDashBoardHeader}
              <div onClick={exportCsv} className="reusableOutlineBtn reusableBtn ms-2">
                Export
              </div>
              <div onClick={() => navigate(`/invoices/add-invoice`)} className="reusableBtnActive reusableBtn ms-2">
                Add Invoice
              </div>
            </div>
          </div>

          {alert.type === 'alert-success' && (
            <CommonAlert
              className="mt-3"
              setIsShowAlert={setAlert}
              isShowAlert={alert.message}
              alertType={alert.type}
            />
          )}

          <div className="table-responsive order-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <b>Order ID</b>
                  </th>
                  <th scope="col">
                    <b>Vendor Name</b>
                  </th>
                  <th scope="col">
                    <b>Invoice Date</b>
                  </th>
                  <th scope="col">
                    <b>Invoice Amount</b>
                  </th>
                  <th scope="col">
                    <b>Action</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <InvoicesTable orderList={orderList} isLoading={isLoading} />
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          {(orderList?.length > 9 || currentPage > 1) && (
            <Pagination
              data={orderList}
              length={orderList?.count}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
      <FilterModal
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter="invoices"
        getData={handleFilterData}
        data={filterData}
      />
      <ToastContainer toastStyle={{ backgroundColor: '#EBF8E7' }} />
    </div>
  );
}

export default Invoices;
