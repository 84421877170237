import React from 'react';
import { AdminPanelHeader } from 'components';
import CoreInputBox from '../../CoreInputBox';

export const InvoicesHeader = ({ searchStr, setSearchStr, setCurrentPage, value }) => {
  const renderSearchBox = () => (
    <div className="w-100">
      <CoreInputBox
        placeholder="Search Order ID"
        setValue={(val) => {
          setCurrentPage(1);
          value.current = val;
          setSearchStr(val);
        }}
        value={searchStr}
      />
    </div>
  );

  return (
    <div className="headerFiles">
      <AdminPanelHeader 
        hideSearch 
        iconTitle="receipt" 
        title="Invoice" 
        searchBox={renderSearchBox()} 
      />
    </div>
  );
}; 