import React, { useEffect, useState } from 'react';
import { CloseWhiteTransParent } from 'res/images';
import CommonToolTip from './CommonToolTip';
import { clickOnElmOnBodyClick, dateFormat, WeightNotUpdatedSinceList } from './Helper';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function FilterChips(props) {
  const { data, setData, clearData, sessionStorageKey } = props;
  const [showFilterData, setShowFilterData] = useState({});
  const ignoreKeys = [
    'tag_ids',
    'tags_data',
    'admin_ids',
    'category_ids',
    'author_ids',
    'patient_ids',
    'active_patients',
    'invoice_ids',
    'vendor_ids',
  ];
  const locations = useSelector((state) => state.sliceReducer.locations);
  const location = useLocation();

  useEffect(() => {
    itrateObject();
  }, [data]);

  const itrateObject = () => {
    let tmpData = {};
    Object.keys(data).map((item) => {
      if (!ignoreKeys.includes(item)) {
        tmpData[item] = data[item];
      }
    });
    setShowFilterData(tmpData);
  };

  let removeValueFromString = (string, removeStr, seprateBy) => {
    if (string && removeStr) {
      let arr = string.split(seprateBy);
      let newArr = [];
      arr.map((item) => {
        if (item != removeStr) {
          newArr.push(item);
        }
      });
      let newArrStr = newArr.join(seprateBy);
      return newArrStr;
    } else {
      return '';
    }
  };

  const handleStringData = (data, key) => {
    let dataArr = data?.split(',');
    let selectedItem;
    return dataArr.map((item, index) => {
      if (item) {
        if (key === 'weight_not_updated') {
          selectedItem = WeightNotUpdatedSinceList.find((val) => val.id === item);
        }
        return (
          <div className="chips capitalize activeTagsStyle pointer" key={index}>
            {key === 'weight_not_updated' ? selectedItem?.title : item === 'whatsapp' ? 'WhatsApp' : item}
            <CommonToolTip
              placement={'bottom'}
              item={<img onClick={() => removeStringData(data, item, key)} src={CloseWhiteTransParent} />}
              showValue={'Remove'}
            />
          </div>
        );
      }
    });
  };

  const handleInsuranceCat = (data, key) => {
    let dataArr = data?.split('|');
    return dataArr.map((item, index) => {
      if (item) {
        return (
          <div className="chips capitalize activeTagsStyle pointer" key={index}>
            {item}
            <CommonToolTip
              placement={'bottom'}
              item={<img onClick={() => removeStringData(data, item, key, '|')} src={CloseWhiteTransParent} />}
              showValue={'Remove'}
            />
          </div>
        );
      }
    });
  };

  const removeStringData = (strData, str, key, seprateBy = ',') => {
    data[key] = removeValueFromString(strData, str, seprateBy);
    setData && setData({ ...data });
    sessionStorageKey && sessionStorage.setItem(sessionStorageKey, JSON.stringify({ ...data }));
  };

  const handleItemClick = (itemData, key, index) => {
    let idremove = String(itemData.id);
    let findKey = key.split('_')[0] + '_';
    let objecItemKey = '';
    ignoreKeys.map((item) => {
      if (item.includes(findKey)) {
        objecItemKey = item;
      }
    });
    let tmpData = [...data[key]];

    tmpData.splice(index, key === 'order_status' ? 1 : 2);
    let newStr = removeValueFromString(data[objecItemKey], idremove);
    data[objecItemKey] = newStr;
    data[key] = tmpData;
    setData && setData({ ...data });
    sessionStorageKey && sessionStorage.setItem(sessionStorageKey, JSON.stringify({ ...data }));
  };

  const itrateArr = (arr, keyStr, showKey = 'title') => {
    if (keyStr === 'priceRange') {
      if (arr.length > 0) {
        const btnData = arr[0] + ' - ' + arr[1];
        return (
          <div className="chips capitalize activeTagsStyle pointer">
            {btnData}
            <img onClick={() => handleItemClick(btnData, keyStr, 0)} src={CloseWhiteTransParent} />
          </div>
        );
      }
    }
    if (keyStr === 'order_status') {
      const orderStatus = {
        draft: 'draft',
        in_progress: 'Pending',
        partially_confirmed: 'Partially Ordered',
        confirmed: 'Order Placed',
        cancelled: 'Cancelled',
      };
      return arr.map((item, index) => {
        return (
          <div className="chips capitalize activeTagsStyle pointer" key={index}>
            {orderStatus[item]}
            <img onClick={() => handleItemClick(item, keyStr, index)} src={CloseWhiteTransParent} />
          </div>
        );
      });
    }
    return arr.map((item, index) => {
      if (arr.length === 0) return null;
      const areAllNumbers = arr.every((item) => typeof item === 'number');

      if (areAllNumbers) {
        const numberString = arr.join(', ');
        return (
          <div className="chips capitalize activeTagsStyle pointer" key={keyStr}>
            {numberString}
            <img onClick={() => handleItemClick(numberString, keyStr, 0)} src={CloseWhiteTransParent} />
          </div>
        );
      } else {
        if (typeof item === 'number') {
          return null;
        }

        return (
          <div className="chips capitalize activeTagsStyle pointer" key={index}>
            {item[showKey]}
            <img onClick={() => handleItemClick(item, keyStr, index)} src={CloseWhiteTransParent} />
          </div>
        );
      }
    });
  };
  let removeDate = (key) => {
    data[key] = removeValueFromString(key, '');
    setData && setData({ ...data });
    sessionStorageKey && sessionStorage.setItem(sessionStorageKey, JSON.stringify({ ...data }));
  };

  const itrateArrDate = (dateRangeStr, keyStr) => {
    if (location.pathname === '/orders' || location.pathname === '/payouts' || location.pathname === '/invoices') {
      //For orders,payouts and invoices dateRange is returned in this format '01/02/2025-19/02/2025' since for DatRange We have use useFormattedDate={true}
      //This if condition is to handle the above mentioned format
      const [startStr, endStr] = dateRangeStr.split('-');
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('/').map(Number);
        return new Date(year, month - 1, day);
      };

      const startDate = parseDate(startStr.trim());
      const endDate = parseDate(endStr.trim());

      return (
        <div className="chips activeTagsStyle capitalize pointer">
          {dateFormat(startDate)} - {dateFormat(endDate)}
          <CommonToolTip
            placement={'bottom'}
            item={<img onClick={() => removeDate(keyStr)} src={CloseWhiteTransParent} />}
            showValue={'Remove'}
          />
        </div>
      );
    } else {
      return (
        <div className="chips activeTagsStyle capitalize pointer">
          {dateFormat(dateRangeStr[0])} - {dateFormat(dateRangeStr[1])}
          <CommonToolTip
            placement={'bottom'}
            item={<img onClick={() => removeDate(keyStr)} src={CloseWhiteTransParent} />}
            showValue={'Remove'}
          />
        </div>
      );
    }
  };

  const itrateArrClinic = (keyStr, key) => {
    let clinicObj = locations.find((item, index) => item.id == keyStr);
    return (
      <div className="chips activeTagsStyle capitalize pointer">
        {clinicObj.title}
        <CommonToolTip
          placement={'bottom'}
          item={<img onClick={() => removeStringData(clinicObj.title, '', key)} src={CloseWhiteTransParent} />}
          showValue={'Remove'}
        />
      </div>
    );
  };

  const renderChipsTags = () => {
    let dataKeys = ['patient_data', 'author_data', 'admin_data', 'invoice_data', 'vendor_data'];
    return Object.keys(showFilterData).map((item, index) => {
      if (dataKeys.includes(item)) {
        return itrateArr(showFilterData[item], item, 'full_name');
      }
      if (item == 'clinic' && showFilterData[item].length > 0) {
        return itrateArrClinic(showFilterData[item], item);
      } else if (item == 'date' && showFilterData[item].length > 0) {
        return itrateArrDate(showFilterData[item], item);
      } else if (item == 'pmsDate' && showFilterData[item].length > 0) {
        return itrateArrDate(showFilterData[item], item);
      } else if (item == 'prescriptionDate' && showFilterData[item].length > 0) {
        return itrateArrDate(showFilterData[item], item);
      } else if (item == 'pmsConsultDate' && showFilterData[item].length > 0) {
        return itrateArrDate(showFilterData[item], item);
      } else if (item == 'insurance_category' && showFilterData[item].length > 0) {
        return handleInsuranceCat(showFilterData[item], item);
      } else if (typeof showFilterData[item] == 'string' && typeof showFilterData[item]) {
        return handleStringData(showFilterData[item], item);
      } else {
        return itrateArr(showFilterData[item], item);
      }
    });
  };

  return (
    <>
      {Object.keys(showFilterData).length > 0 && (
        <div className="chipsTagsView">
          {renderChipsTags()}
          <div
            className="chips1 theme-border"
            onClick={(e) => {
              e.stopPropagation();
              clearData();
              clickOnElmOnBodyClick('datePickerContainer', 'rs-picker-toggle-clean', 'class');
            }}
          >
            Clear All
          </div>
        </div>
      )}
    </>
  );
}

export default FilterChips;
