import React from "react";
import { CoreInputBox, CoreDropDown } from "whealth-core-web/components";
import { formulationData } from "whealth-core-web/components/Helper";

const AddNewMedicine = ({
  selectedRowDetails,
  formData,
  handleInputChange,
  inputStyles,
  addNewMedicineErrorMsg,
  isItPostReq,
  setFormData,
  VariationCard,
  addVariation,
}) => {
  const styles = {
    addAnotherVarDiv: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "20px",
      marginLeft: "10px",
    },
    addAnotherVar: {
      color: "#0F62FE",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "500",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="labelStyle">
        Name <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <CoreInputBox
        retuired={true}
        value={selectedRowDetails?.name || formData?.name}
        setValue={(data) => handleInputChange("name", data)}
        inputStyle={inputStyles}
        msgStyle={{ color: "red" }}
        showMSG={addNewMedicineErrorMsg?.medicineName}
        disabled={!isItPostReq}
      />
      <div className="labelStyle">
        Formulation <span style={{ color: "#AB1315" }}>*</span>
      </div>
      <div className=" w-100 mb-4">
        <CoreDropDown
          selectStyle={{
            width: "345px",
            height: "36px",
            borderRadius: "5.03px",
            border: "#D9D9D9 0.75px solid",
          }}
          value={formData?.formulation || ""}
          data={formulationData()}
          placeholder="Select Formulation"
          valueKey="id"
          retuired
          setValue={(inputVal) => {
            if (inputVal && inputVal != "") {
              setFormData((prevData) => ({
                ...prevData,
                formulation: inputVal,
              }));
            } else {
              setFormData((prevData) => ({
                ...prevData,
                formulation: "",
              }));
            }
          }}
          msgStyle={{ color: "red" }}
          showMSG={addNewMedicineErrorMsg?.formulation}
          disabled={!isItPostReq}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {formData?.product?.product_line_items?.map((_, index) =>
          VariationCard(index)
        )}
      </div>
      <div className="">
        <span className="addAnotherVar" onClick={addVariation}>
          + Add Another Variation
        </span>
      </div>
    </div>
  );
};

export default AddNewMedicine;
