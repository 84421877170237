import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import "./drawer.scss";
import CoreInputBox from "../CoreInputBox";
import { format } from "date-fns";
import CoreTextArea from "../CoreTextArea";

const ViewOrderDrawer = ({ isOpen, onClose, orderDetails }) => {
  /**
   * Format the tentative delivery date based on the ETA value
   * @param {Object} eta - The ETA object containing eta_from timestamp
   * @returns {string} Formatted delivery date or status
   *
   * Logic:
   * - If eta_from is 0: Order has been delivered
   * - If eta_from exists: Format the timestamp to date string
   * - If eta_from is null/undefined: Show N/A
   */
  const getTentativeDeliveryDate = (eta, updated_at) => {
    if (eta?.eta_from === 0) {
      if (!updated_at) {
        return "Delivered";
      }
      return (
        "Delivered on " +
        format(new Date(updated_at * 1000), "dd MMM yyyy hh:mm a")
      );
    }
    if (!eta?.eta_from) {
      return "N/A";
    }

    return format(new Date(eta.eta_from * 1000), "dd MMM yyyy hh:mm a");
  };

  return (
    <>
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <h4>1MG Order Details</h4>
          <button
            onClick={onClose}
            className="close-button"
            style={{ position: "absolute", right: 20, top: 2 }}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="drawer-content">
          {orderDetails?.data?.result?.grouped_orders?.map((order, index) => (
            <div key={index} className="order-group">
              <h5 className="mb-4">Order {index + 1}</h5>
              <div className="mb-4">
                <p style={{ opacity: 0.8 }}>Skus</p>
                {order?.skus?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <span>{item?.name}</span>
                      <span>x</span>
                      <span>{item?.normalized_quantity}</span>
                    </div>
                  );
                })}
              </div>
              <CoreInputBox
                label="Purchase Order Number (PO)"
                value={order?.order_id || "N/A"}
                disabled={true}
                inputStyle={{ marginBottom: "16px" }}
              />

              <CoreTextArea
                label="Current Status"
                value={order?.order_status?.title || "N/A"}
                disabled={true}
                inputStyle={{ marginBottom: "16px" }}
                rows={1}
              />

              <CoreTextArea
                label="Sub Status"
                value={order?.order_status?.subtitle || "N/A"}
                disabled={true}
                inputStyle={{ marginBottom: "16px" }}
                rows={1}
              />

              <CoreInputBox
                label="Tentative Delivery Date"
                value={getTentativeDeliveryDate(order?.eta, order?.updated)}
                disabled={true}
                inputStyle={{ marginBottom: "16px" }}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={onClose}
      ></div>

      <style jsx>{`
        .drawer {
          position: fixed;
          right: -400px;
          top: 0;
          width: 400px;
          height: 100vh;
          background: white;
          box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
          transition: right 0.3s ease;
          z-index: 1000;
          padding: 20px;
          overflow-y: auto;
        }

        .drawer.open {
          right: 0;
        }

        .drawer-header {
          padding-bottom: 20px;
          border-bottom: 1px solid #eee;
          margin-bottom: 20px;
          position: relative;
        }

        .drawer-content {
          padding: 20px 0;
          min-height: 200px;
        }

        .order-group {
          margin-bottom: 32px;
          padding-bottom: 24px;
          border-bottom: 1px solid #eee;
        }

        .order-group:last-child {
          border-bottom: none;
        }

        .detail-group {
          margin-bottom: 24px;
        }

        .detail-group label {
          display: block;
          font-size: 14px;
          color: #666;
          margin-bottom: 8px;
        }

        .detail-value {
          font-size: 16px;
          color: #333;
          font-weight: 500;
        }

        .close-button {
          background: none;
          border: none;
          cursor: pointer;
          color: #666;
        }

        .close-button:hover {
          color: #333;
        }

        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
          z-index: 999;
        }

        .overlay.active {
          opacity: 1;
          visibility: visible;
        }
      `}</style>
    </>
  );
};

ViewOrderDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderDetails: PropTypes.object,
};

export default ViewOrderDrawer;
