import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { dateFormat, timeFormat, titlizeString } from '../Helper';
import Loader from '../Loader';
import { expandIcon, locationIcon, moreVert } from '../../assets/images';
import RenderApolloTab from './RenderApolloTab';
import { AdminPanelHeader } from 'components';
import CoreInputBox from '../CoreInputBox';
import ReusableButton from '../ReusableButton';
import { addIconWhite } from 'res/images';
import { Popover, Whisper, Dropdown, Button } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { setManualOrderId, setPatientAddress, setManualPatient } from 'redux/Slices';
import { Pagination } from 'whealth-core-web/components';

function PatientOrder(props) {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState();
  const [orderDetail, setOrderDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [downloadedOrder, setDownloadedOrder] = useState();
  const [totalPages, setTotalPages] = useState();
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState('');
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const containerRef = useRef(null);
  const ApiClient = useApiManager();
  const { id, oid } = useParams();
  const { index } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const patientDetails = useSelector((state) => state?.sliceReducer?.patientManualDetails);
  const value = useRef('');
  const [orderLoadingState, setOrderLoadingState] = useState(undefined);
  useEffect(() => {
    getOrderDetail();
  }, [id]);

  // useEffect(() => {
  //   if (!openDetailModal) searchOrders();
  // }, [searchStr, currentPage, openDetailModal]);

  const downloadOrder = (orderId, oid) => {
    //const ORDER_ID = orderId === undefined ? orderId : Number(oid);
    const ORDER_ID = orderId || Number(oid) || localStorage.getItem('orderId');
    ApiClient.downloadOrder(ORDER_ID, { responseType: 'arraybuffer' })
      .then((res) => {
        const imageUrl = res.data.image_url;
        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = `order_${orderId}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log('downloadOrder error: ', err);
      });
  };

  const searchOrders = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr };
    ApiClient.searchOrders(params)
      .then((res) => {
        setIsLoading(false);
        if (value.current === params.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search a Order by Patent name or Order ID'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const getOrderDetail = (index) => {
    setIsLoading(true);
    ApiClient.getOrderDetail(id)
      .then((res) => {
        setIsLoading(false);
        setOrderList(res.data);
        setOrderDetail(res.data);
        props?.setOrderList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: 'Partially Ordered',
      id: 'ordered_partially',
    },
    {
      title: 'Unfulfilled',
      id: 'in_progress',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
    {
      title: 'Draft',
      id: 'draft',
    },
  ];

  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    return (
      <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
          <Dropdown.Item eventKey='1'>View Details</Dropdown.Item>
          <Dropdown.Item eventKey='2'>Download</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  });

  const handleViewOrder = (id, address, user) => {
    dispatch(setManualOrderId(id));
    dispatch(setPatientAddress(address));
    dispatch(setManualPatient(user));
  };

  const handleDetail = (index, orderDetail, orderStatus, patientName, patientId) => {
    const idStr = orderDetail?.order_id;
    //const id = Number(idStr.substring(6));
    const orderId = Number(idStr.substring(6));
    const userAddress = orderDetail?.user?.address;
    const user = orderDetail.user;
    handleViewOrder(orderId, userAddress, user);
    if (orderStatus === 'draft') {
      //navigate(`/orders/${index}/${orderStatus}`);
      navigate(`/orders/${index}/${orderId}/${patientId}/${orderStatus}/${idStr}`, {
        state: { orderStatus, patientName, orderId },
      });
    } else {
      navigate(`/patient/${id}/dashboard/orders/${orderId}/${index}/`);
    }
  };

  const renderDropdown = (item, index) => {
    const patientName = item?.user?.full_name;
    const patientId = item?.user?.id;
    const idStr = item?.order_id;
    const orderId = Number(idStr.substring(6));
    const handleSelect = (eventKey) => {
      if (eventKey === '1') {
        // navigate(`/patient/${id}/dashboard/orders/${index}/`)
        handleDetail(index, item, item.order_status, patientName, patientId);
      } else if (eventKey === '2') {
        // Add functionality for "Download" here
        setOrderId(orderId);
        downloadOrder(orderId, oid);
      }
    };

    return (
      <Whisper trigger='click' placement='auto' speaker={<MenuPopover onSelect={handleSelect} />}>
        <Button appearance='subtle'>
          <img height={12} src={moreVert} className='ml-10 me-1 pointer' alt='More options' />
        </Button>
      </Whisper>
    );
  };

  const renderAllOrders = (item, index) => {
    return (
      <>
        <div class='flex justify-between flex-row'>
          <div class='d-flex justify-content-between items-center mb-2 space-x-4'>
            <div class='flex items-center space-x-4 order-text'>Order ID: {item?.order_id}</div>
            <div class='flex items-center space-x-4'>
              <span class='text-gray-700 order-date'>
                Ordered On: {dateFormat(item?.updated_at)}|{timeFormat(item?.updated_at)}
              </span>
              {renderDropdown(item, index)}
            </div>
          </div>

          <div class='d-flex justify-content-between items-center mb-1 space-x-4'>
            <div class='flex items-center text-gray-700 space-x-4 order-address'>
              <img src={locationIcon} /> {item?.shipping_address}
            </div>
            <div class='flex items-center text-gray-700 space-x-4' style={{ marginRight: '28px' }}>
              <div className={`d-flex align-items-center`}>
                <span
                  className={`status-text align-items-center badge capitalize active align-items-center d-flex ${item.ehr_order_status}`}
                >
                  {titlizeString(orderStatus.find((item3) => item3.id == item.ehr_order_status)?.title) ||
                    item.item_status}{' '}
                  {item.ehr_order_status == 'to_order' && `(${item.pending_item})`}
                </span>
              </div>
            </div>
          </div>
          <div class='d-flex items-center'>
            <span class='text-gray-700 order-address'>₹ {item.total_amount}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div ref={containerRef}>
        {!props?.index && (
          <div className='d-flex justify-content-center mt-5 mb-2'>
            {orderDetail?.length || isLoading ? '' : 'No data found'}
          </div>
        )}
        {location.pathname.includes(`/patient/${id}/dashboard`) && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'inline-block', width: 'auto' }}>
              <ReusableButton
                onClick={() => navigate(`/orders/new-orders/${id}`)}
                icon={addIconWhite}
                title='Add Order'
                active
              />
            </div>
          </div>
        )}
        {!props?.index ? (
          <>
            {orderDetail?.map((item, index) => {
              return <div className='cardDataBorder w-100 patient-order-container'>{renderAllOrders(item, index)}</div>;
            })}
            {/* <div className='d-flex justify-content-end mb-4'>
                <Pagination
                data={orderList}
                length={orderList?.count}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div> */}
          </>
        ) : (
          // <RenderApolloTab
          //   patientId={id}
          //   order={orderDetail}
          //   currentOrderIndex={index}
          //   setOrderList={setOrderDetail}
          //   searchOrder={searchOrders}
          // />
          <RenderApolloTab
            order={orderList}
            setOrderList={setOrderList}
            searchOrder={searchOrders}
            screenLoading={isLoading}
            orderLoadingState={orderLoadingState}
            setOrderLoadingState={setOrderLoadingState}
          />
        )}
        <Loader show={isLoading} />
        {props?.index && (
          <div className='d-flex justify-content-end'>
            <div className='patientPagination mt-3'>
              <Pagination
                data={orderList}
                length={orderList?.count}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PatientOrder;