import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { location, noteSave } from "res/images";
import { profileImage } from "../Helper";
import Select2DropDown from "../common/Select2DropDown";
import CoreInputBox from "../CoreInputBox";
import CoreTextArea from "../CoreTextArea";
import { Modal } from "rsuite";
import PatientPrescription from "./PatientPrescription";
import RecordOrderPayment from "./RecordOrderPayment";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetManualOrderQuery,
  useGeneratePaymentLinkMutation,
  useAddGenericNotesMutation,
  useGetNotesQuery,
} from "redux/dashboardApis";
import { Loader } from "whealth-core-web/components";
import useApiManager from "networking/ApiManager";
import { useParams } from "react-router-dom";
import {
  setReduxNotesData,
  setDraftOrderDetails,
  setPatientAddress,
  setManualOrderSummary,
  setFromViewOrderToOrderDetailSummary,
} from "redux/Slices";
import { Dropdown } from "rsuite";
import { parse } from "date-fns";

const initialMedicines = [];

const ViewOrder = (props) => {
  const { ordersId, notesData } = props;
  const dispatch = useDispatch();
  const {
    orderId: urlOrderId,
    orderStatus,
    patientId: urlPatientId,
  } = useParams();
  const ApiClient = useApiManager();
  const patientAddress = useSelector(
    (state) => state?.sliceReducer?.patientAddress
  );
  const patientManualDetails = useSelector(
    (state) => state?.sliceReducer?.patientManualDetails
  );
  const manualOrderItems = useSelector(
    (state) => state?.sliceReducer?.manualOrderItems
  );
  const manualOrderSummary = useSelector(
    (state) => state?.sliceReducer?.manualOrderSummary
  );
  const manualOrderId = useSelector(
    (state) => state?.sliceReducer?.manualOrderId
  );
  const reduxNotesData = useSelector(
    (state) => state?.sliceReducer?.reduxNotesData
  );
  const userData = useSelector((state) => state?.sliceReducer?.userData);

  const userImage = (item) => {
    const imgSource = profileImage(item.gender, item["profile_url"]);
    return (
      <img src={imgSource} style={{ height: 24 }} className="rounded-circle" />
    );
  };

  const [orderDetail, setOrderDetail] = useState();
  const [paymentLink, setPaymentLink] = useState("");
  const [medicines, setMedicines] = useState(initialMedicines);
  const [notes, setNotes] = useState("");
  const [notesArr, setNotesArr] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isRecordPayment, setRecordPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [manualOrderDetails, setManualOrderDetails] = useState();
  const [patientApiAddress, setPatientApiAddress] = useState([]);
  const [patientApiDetails, setPatientApiDetails] = useState({});
  const [orderSummary, setOrderSummary] = useState({
    order_total: 0,
    shipping_amount: 0,
    discount: 0,
    discount_percentage: 0,
    order_paid_amount: 0,
  });
  const [actualOrderId, setActualOrderId] = useState(null);
  const [venderOptions, setVendorOptions] = useState([]);
  const [vendorValueArray, setVendorValueArray] = useState([]);
  const [generatePaymentLink, generatePaymentLinkResponse] =
    useGeneratePaymentLinkMutation();
  const manualOrderIdString = manualOrderDetails?.data?.order_id;
  const patientDetails = useSelector(
    (state) => state?.sliceReducer?.patientManualDetails
  );
  const [genericNotes, genericNotesResponse] = useAddGenericNotesMutation();
  //const notesData = useGetNotesQuery(manualOrderId);
  useEffect(() => {
    localStorage.setItem("orderId", urlOrderId);
    getVendorDetail();
  }, []);

  useEffect(() => {
    if (urlPatientId) {
      getPatientAddress(urlPatientId);
      getPatientDetails(urlPatientId);
      //getSelectedOrderDetail(urlPatientId, urlOrderId);
    }
  }, []);

  useEffect(() => {
    dispatch(setFromViewOrderToOrderDetailSummary(orderSummary));
  }, [orderSummary]);

  useEffect(() => {
    dispatch(setReduxNotesData(notesArr));
  }, [notesArr]);

  useEffect(() => {
    const storedOrderId = localStorage.getItem("orderId");
    if (urlOrderId) {
      setActualOrderId(urlOrderId);
    } else if (manualOrderId) {
      setActualOrderId(manualOrderId);
    } else if (storedOrderId) {
      setActualOrderId(storedOrderId);
    }
  }, [urlOrderId, manualOrderId]);

  let totalPriceBeforeDiscount = 0;
  let totalDiscount = 0;
  medicines?.forEach((medicine) => {
    totalPriceBeforeDiscount += medicine.mrp * medicine.quantity;
    totalDiscount +=
      ((medicine.mrp * medicine.discount) / 100) * medicine.quantity;
  });
  const totalDiscountPercentage = (
    (totalDiscount / totalPriceBeforeDiscount) *
    100
  ).toFixed(2);
  const totalPriceAfterDiscount = totalPriceBeforeDiscount - totalDiscount;
  const getVendorDetail = () => {
    ApiClient.getVendorDetail()
      .then((res) => {
        setIsLoading(false);
        setVendorOptions(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getManualOrderDetail = (id) => {
    ApiClient.getManualOrder(id)
      .then((res) => {
        setManualOrderDetails(res);
        dispatch(setDraftOrderDetails(res?.data?.order_details));
        dispatch(setPatientAddress(res?.data?.selected_address));

        const ordrId = res?.data?.order_id;
        localStorage.setItem("ordrId", ordrId);
        //const discount = parseFloat(res?.data?.before_discount_amount) - parseFloat(res?.data?.discounted_amount);
        //const disc_per = parseFloat(((discount/parseFloat(res?.data?.before_discount_amount) )*100).toFixed(2));
        // setOrderSummary({
        //   order_total: parseFloat(res?.data?.before_discount_amount),
        //   shipping_amount: parseFloat(res?.data?.shipping_amount),
        //   discount: discount,
        //   discount_percentage: disc_per,
        //   source: res?.data?.source,
        //   order_paid_amount: res?.data?.total_amount
        // })
      })
      .catch((err) => {
        console.log("getManualOrder err: ", err);
      });
  };

  const getAllNotes = (id) => {
    ApiClient.getOrderNotes(id)
      .then((res) => {
        setNotesArr(res.data.results);
        setIsNotesLoading(false);
      })
      .catch((err) => {
        console.log("getNotes error: ", err);
        setIsNotesLoading(false);
      });
  };

  const getPatientAddress = (id) => {
    ApiClient.getPatientAddress(id)
      .then((res) => {
        setPatientApiAddress(res.data);
      })
      .catch((err) => {
        console.log("getPatientAddress error: ", err);
      });
  };

  const getPatientDetails = (id) => {
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setPatientApiDetails(res.data);
      })
      .catch((err) => {
        console.log("getNotes error: ", err);
      });
  };

  const getSelectedOrderDetail = (id, oid) => {
    ApiClient.getSelectedOrderDetail(id, oid)
      .then((res) => {
        setOrderDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (actualOrderId) {
      setIsNotesLoading(true);
      if (typeof actualOrderId === "object") {
        getManualOrderDetail(actualOrderId.id);
        getAllNotes(actualOrderId.id);
      } else {
        getManualOrderDetail(actualOrderId);
        getAllNotes(actualOrderId);
      }
    }
  }, [actualOrderId]);

  useEffect(() => {
    if (manualOrderDetails) {
      const SUMMARY = {
        orderTotal: manualOrderDetails?.data?.before_discount_amount,
        discount: manualOrderDetails?.data?.discounted_amount,
        discount_percentage: (
          (parseFloat(manualOrderDetails?.data?.discounted_amount) /
            parseFloat(manualOrderDetails?.data?.before_discount_amount)) *
          100
        ).toFixed(2),
        shipping: manualOrderDetails?.data?.shipping_amount,
        total: manualOrderDetails?.data?.total_amount,
      };
      setOrderSummary(SUMMARY);
      dispatch(setManualOrderSummary(SUMMARY));
    }
  }, [manualOrderDetails]);

  // useEffect(() => {
  //   if (notesData && notesData.data) {
  //     setNotesArr(notesData.data.data);
  //   }
  // }, [notesData]);

  useEffect(() => {
    const storedMedicines = localStorage.getItem("medicines");
    if (storedMedicines) {
      setMedicines(JSON.parse(storedMedicines));
    }
  }, []);

  useEffect(() => {
    const storedPatientAddress = localStorage.getItem("patientAddress");
    if (storedPatientAddress) {
      setPatientApiAddress(JSON.parse(storedPatientAddress));
    }
  }, []);

  useEffect(() => {
    if (patientAddress && Object.keys(patientAddress).length > 0) {
      localStorage.setItem("patientAddress", JSON.stringify(patientAddress));
    }
  }, [patientAddress]);

  useEffect(() => {
    if (medicines.length > 0) {
      localStorage.setItem("medicines", JSON.stringify(medicines));
    }
  }, [medicines]);

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };

  const handleQuantityChange = (id, amount) => {
    const updatedMedicines = medicines.map((medicine) =>
      medicine.id === id
        ? { ...medicine, quantity: Math.max(medicine.quantity + amount, 0) }
        : medicine
    );
    setMedicines(updatedMedicines);
    calculateOrderSummary(updatedMedicines);
  };

  function capitalizeFirstLetter(string) {
    if (typeof string !== "string" || string.length === 0) {
      return ""; // Return an empty string or handle it as needed
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const calculateOrderSummary = (medicines) => {
    let orderTotal = 0;
    let discount = 0;
    medicines.forEach((medicine) => {
      orderTotal += medicine.price * medicine.quantity;
      discount +=
        ((medicine.price * medicine.discount) / 100) * medicine.quantity;
    });
    const total = orderTotal - discount;
    setOrderSummary({ orderTotal, discount, shipping: 0, total });
  };

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const medicineList = [
    { title: "500 pellets", id: "500_pellets" },
    { title: "1000 pellets", id: "1000_pellets" },
  ];

  const handleCopyPaymentLink = () => {
    navigator.clipboard
      .writeText(paymentLink)
      .then(() => {
        alert("Payment link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleSaveNotes = async () => {
    if (!notes.trim()) {
      return;
    }
    const data = {
      content: notes,
      model_name: "order",
      model_id: manualOrderId | urlOrderId | ordersId,
    };
    try {
      const response = await genericNotes({ data });
      setNotesArr([...notesArr, response.data.data]);
      //dispatch(setReduxNotesData([...notesArr, response.data.data]));
      if (response.error) {
        throw new Error(response.error);
      }
      setNotes("");
    } catch (e) {
      console.error("Payment recording failed:", e);
    }
  };

  const handleGeneratePaymentLink = async () => {
    setIsLoading(true);
    try {
      const response = await generatePaymentLink({
        id: manualOrderId | urlOrderId | ordersId,
      });
      setPaymentLink(response?.data?.data?.payment_link);
    } catch (error) {
      console.error("Payment link generation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPatientDetails = () => {
    const details =
      Object.keys(patientManualDetails).length === 0
        ? patientApiDetails
        : patientManualDetails;
    const address =
      Object.keys(patientAddress).length === 0
        ? patientApiAddress[1]
        : patientAddress;
    return (
      <div className="order-header">
        <div
          className="d-flex align-items-center"
          style={{
            background: "#F9FAFE",
            padding: "10px",
            borderRadius: "8px",
            width: "50%",
          }}
        >
          <div className="mr-2">
            {`Source: ${manualOrderDetails?.data?.source || "Manual"}`}
            <p>
              Order ID :{" "}
              {orderDetail?.order_id || localStorage.getItem("ordrId")}
            </p>
          </div>
          <div className="vl">.</div>
          <div className="mx-2 patient-name d-flex gap-2">
            {userImage(patientApiDetails)}
            <div>{details?.full_name}</div>
          </div>
          <div className="mx-2 patient-address d-flex">
            <div className="mx-2">
              <img src={location} alt="location" />
            </div>

            {orderDetail?.shipping_address ? (
              <div>{orderDetail?.shipping_address}</div>
            ) : (
              <div>
                <div>
                  {address?.address_line1}, {address?.address_line2}
                </div>
                <div>
                  {address?.city}, {address?.state}, {address?.pincode}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => {
            setEditMode(true);
          }}
          className="reusableBtnActive  reusableBtn "
          style={{
            background: "#fff",
            border: "1px solid #133696",
            color: "#133696",
          }}
        >
          View Prescription
        </div>
        <Modal
          backdrop="static"
          size="md"
          open={isEditMode}
          onClose={() => {
            setEditMode(false);
          }}
        >
          <Modal.Header>
            <div className="settingFormOutline p-0">
              <div className="formTitle mb-0">Prescription</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PatientPrescription
              patientId={patientDetails?.id | urlPatientId}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  if (isNotesLoading) {
    return <Loader show={isNotesLoading} />;
  }

  return (
    <div className="order-details">
      <p
        className="status-text align-items-center capitalize active align-items-center d-flex"
        style={{
          position: "absolute",
          background: "#EBEFFC",
          borderRadius: "0.5rem",
          lineHeight: "1rem",
          color: "#243B86",
          padding: "3px 8px 3px 8px",
        }}
      >
        Draft
      </p>
      {renderPatientDetails()}
      <div className="mt-4 mb-4 table-responsive table-responsive1">
        <table className="table" style={{ marginBottom: "0" }}>
          <thead>
            <tr style={{ border: "1px solid #d9d9d9" }}>
              <th>Sr.</th>
              <th>Medicine</th>
              {/* <th>SKU</th> */}
              <th>Vendor</th>
              <th>Quantity</th>
              <th>Discount</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody style={{ border: "1px solid #d9d9d9" }}>
            {manualOrderDetails?.data?.order_details?.map((medicine, index) => {
              return (
                <tr key={medicine.id}>
                  <td style={{ whiteSpace: "nowrap" }}>{index + 1}</td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                  >{`${capitalizeFirstLetter(
                    medicine?.product_item?.product?.medicine?.formulation
                  )} ${medicine?.product_item.product.product_name}`}</td>
                  {/* <td style={{ whiteSpace: 'nowrap' }}>{medicine?.product_item.sku}</td> */}
                  <td style={{ whiteSpace: "nowrap" }}>
                    {medicine?.vendor?.vendor_logo && (
                      <img
                        src={medicine?.vendor?.vendor_logo}
                        height="15px"
                        width="15px"
                        style={{
                          margin: "-2px 4px 0 3px",
                          objectFit: "contain",
                        }}
                      />
                    )}{" "}
                    {medicine?.vendor?.vendor_name}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {medicine?.quantity.toString().padStart(2, "0")}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{`${Math.round(
                    medicine.discount_percentage
                  )}%`}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {medicine.item_mrp - medicine.item_amount > 0 ? (
                      <div>
                        <div>Rs. {medicine.item_amount}</div>
                        <div
                          style={{
                            fontSize: "12px",
                            textDecoration: "line-through",
                          }}
                        >
                          Rs. {medicine.item_mrp}
                        </div>
                      </div>
                    ) : (
                      <div>Rs. {medicine.item_amount}</div>
                    )}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    Rs.{" "}
                    {(
                      parseFloat(medicine.item_amount) * medicine.quantity
                    ).toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="row justify-content-end">
        <div className="col-lg-4">
          <div className="order-summary p-3 bg-light rounded">
            <h6 className="fw-bold mb-3">Order Summary</h6>
            <div className="d-flex justify-content-between mb-2">
              <div>Order Total</div>
              <div>
                Rs. {manualOrderSummary?.orderTotal || orderSummary?.orderTotal}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div>Discount</div>
              <div>
                -Rs.{" "}
                {manualOrderSummary.discount
                  ? Number(manualOrderSummary.discount).toFixed(2)
                  : Number(orderSummary.discount).toFixed(2)}{" "}
                {`(${
                  orderSummary?.discount_percentage ||
                  manualOrderSummary.discount_percentage
                }%)`}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div>Shipping</div>
              <div style={{ width: "40%" }}>
                <CoreInputBox
                  disabled={true}
                  // value={
                  //   manualOrderSummary?.shipping == '0.00'
                  //     ? 'Free'
                  //     : manualOrderSummary?.shipping || orderSummary?.shipping || ''
                  // }
                  value="Free"
                  setValue={(value) =>
                    setOrderSummary({
                      ...orderSummary,
                      shipping: parseFloat(value || 0),
                    })
                  }
                />
              </div>
            </div>
            <div className="hrLine mb-3" />
            <div className="d-flex justify-content-between fw-bold">
              <div>Total</div>
              <div>
                Rs.{" "}
                {(parseFloat(orderSummary?.total).toFixed(2) ||
                  parseFloat(manualOrderSummary?.total)) ??
                  0}
              </div>
            </div>
            <div className="hrLine mb-3" />
            <div className="d-flex justify-content-between">
              <div
                onClick={() => {
                  setRecordPayment(true);
                }}
                className="reusableBtnActive  reusableBtn "
                style={{
                  background: "#fff",
                  border: "1px solid #133696",
                  color: "#133696",
                  marginRight: "3px",
                }}
              >
                Record Payment
              </div>
              <Modal
                backdrop="static"
                size="md"
                open={isRecordPayment}
                onClose={() => {
                  setRecordPayment(false);
                }}
              >
                <Modal.Header>
                  <div className="settingFormOutline p-0">
                    <div className="formTitle mb-0 text-center mb-4">
                      Record Payment
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <RecordOrderPayment
                    orderSummary={orderSummary}
                    manualOrderId={manualOrderIdString}
                  />
                </Modal.Body>
              </Modal>
              <div
                onClick={
                  paymentLink === ""
                    ? handleGeneratePaymentLink
                    : handleCopyPaymentLink
                }
                className="reusableBtnActive  reusableBtn "
                style={
                  paymentLink === ""
                    ? {
                        background: "#fff",
                        border: "1px solid #133696",
                        color: "#133696",
                        marginLeft: "3px",
                      }
                    : {
                        background: "#243B86",
                        color: "#fff",
                        marginLeft: "3px",
                        marginLeft: "3px",
                      }
                }
              >
                {isLoading ? (
                  <Loader show={isLoading} />
                ) : paymentLink === "" ? (
                  "Generate Link"
                ) : (
                  "Copy Pay Link"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hrLine mb-3" style={{ background: "#D1DCFF" }} />
      <div className="row">
        <div className="col-lg-12">
          <div className="notes-section mt-3">
            <label className="fw-bold">Notes</label>
            <div className="textarea-container">
              <textarea
                value={notes}
                onChange={handleNoteChange}
                className="form-control mb-3"
              />
              <button className="save-button" onClick={handleSaveNotes}>
                <img src={noteSave} alt="Save Note" />
              </button>
            </div>
            {notesArr.length > 0 ? (
              notesArr
                .slice()
                .reverse()
                .map((note) => (
                  <div key={note?.id} className="note-container mt-2">
                    <div className="mr-2" style={{ marginRight: "10px" }}>
                      <img
                        src={profileImage(
                          patientDetails.gender,
                          userData?.profile_url
                        )}
                        style={{ height: 40 }}
                        alt="Profile"
                      />
                    </div>
                    <div className="note">
                      <div className="note-time">
                        {formatDate(note?.updated_at)}
                      </div>
                      <div className="note-content">{note?.content}</div>
                    </div>
                  </div>
                ))
            ) : reduxNotesData.length > 0 ? (
              reduxNotesData
                .slice()
                .reverse()
                .map((note) => (
                  <div key={note?.id} className="note-container mt-2">
                    <div className="mr-2" style={{ marginRight: "10px" }}>
                      <img
                        src={profileImage(
                          patientDetails.gender,
                          userData?.profile_url
                        )}
                        style={{ height: 40 }}
                        alt="Profile"
                      />
                    </div>
                    <div className="note">
                      <div className="note-time">
                        {formatDate(note?.updated_at)}
                      </div>
                      <div className="note-content">{note?.content}</div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="note-container">
                <div className="mr-2" style={{ marginRight: "10px" }}>
                  <img
                    src={profileImage(
                      patientDetails.gender,
                      userData?.profile_url
                    )}
                    style={{ height: 40 }}
                    alt="Avatar"
                  />
                </div>
                <div className="note" style={{ height: "40px" }}>
                  <div className="note-time"></div>
                  <div className="note-content"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;