import React from "react";
import PropTypes from "prop-types";
import auth_axios from "networking/AuthAxios";
import { toast } from "react-toastify";

const Cancel1MGOrder = ({ isOpen, onClose, onConfirm, orderDetails }) => {
  if (!isOpen) return null;
  console.log("orderDetails", orderDetails);
  const handleConfirm = async () => {
    try {
      const response = await auth_axios.patch(
        `/manual_order/cancel_1mg?order_id=${orderDetails?.id}`
      );

      if (response.data?.success === false) {
        toast.error(response.data?.message || "Failed to cancel order");
        return;
      }

      toast.success("Order cancelled successfully");
      onConfirm();
    } catch (error) {
      console.error("Error cancelling order:", error);
      toast.error(error.response?.data?.message || "Failed to cancel order");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h4>Cancel Order</h4>
          <button className="close-button" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </div>

        <div className="modal-body">
          <p>
            Are you sure you want to cancel this order? This will remove only
            1MG items from the order.
          </p>
        </div>

        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={onClose}>
            No, Keep Order
          </button>
          <button className="btn btn-danger" onClick={handleConfirm}>
            Yes, Cancel Order
          </button>
        </div>
      </div>

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          min-width: 400px;
          max-width: 600px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid #eee;
        }

        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
          color: #666;
          padding: 0 5px;
        }

        .close-button:hover {
          color: #333;
        }

        .modal-body {
          margin-bottom: 20px;
        }

        .order-details {
          background: #f8f9fa;
          padding: 15px;
          border-radius: 4px;
          margin-top: 10px;
          border: 1px solid #eee;
        }

        .modal-footer {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding-top: 15px;
          border-top: 1px solid #eee;
        }

        .btn {
          padding: 8px 16px;
          border-radius: 4px;
          border: none;
          cursor: pointer;
          font-weight: 500;
          transition: all 0.2s ease;
          text-decoration: none;
        }

        .btn:hover {
          transform: translateY(-1px);
          text-decoration: none;
        }

        .modal-footer .btn-secondary {
          background: #fff;
          color: #666;
          border: 1px solid #ddd;
          text-decoration: none;
        }

        .modal-footer .btn-secondary:hover {
          background: #f8f9fa;
          border-color: #ccc;
          color: #666;
          text-decoration: none;
        }

        .modal-footer .btn-danger {
          background: #dc3545;
          color: white;
          border: 1px solid #dc3545;
          text-decoration: none;
        }

        .modal-footer .btn-danger:hover {
          background: #c82333;
          border-color: #bd2130;
          color: white;
          text-decoration: none;
        }
      `}</style>
    </div>
  );
};

Cancel1MGOrder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  orderDetails: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default Cancel1MGOrder;
